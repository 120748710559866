/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useMemo } from "react"
import {
  Button,
  Row,
  Col,
  Card,
  Space,
  Input,
  Divider,
  List,
  Typography,
  message,
  Modal,
} from "antd"
import { useParams, useNavigate } from "react-router-dom"
import { DetailPageContent } from "@src/components/DetailPageComponents/Content"
import { DetailPageHeader } from "@src/components/DetailPageComponents/Header"
import { DetailPageContainer } from "@src/components/DetailPageComponents/PageContainer"
import Access from "@src/components/Access"
import { useAccess } from "@src/contents/useAccess"
import TableCom from "../../../../components/TableCom"
import { RollbackOutlined } from "@ant-design/icons"
import { getUrl, loanCompanyList } from "../../constant"
import { getFileUrl } from "@src/utils/file.ts"
import PreviewFile from "@src/components/PreviewFile"

import {} from "../columns"
import { checkFile, getLoanSupplierDetails } from "../../service"
import "./index.scss"
import { checkValueToContinue } from "../../../../utils/utils"

const supplierAccessDetails = () => {
  const navigate = useNavigate()
  const access = useAccess()
  const searchParams = useParams()
  const [resultData, setResultData] = useState<any>([])
  const [resultFile, setResultFile] = useState<any>([])
  const [pdfModal, setPdfModal] = useState<any>({
    visible: false,
    urls: [],
  })
  const [info, setInfo] = useState<any>({})
  const getInfo = async (id: any) => {
    if (!id) return
    const res = await getLoanSupplierDetails(id)
    if (res?.code === 200) {
      setInfo(res?.data)
      const resultList = loanCompanyList(res?.data)
      setResultFile(resultList)
    }
  }
  // 下载注销协议  或者申请书
  const handleDownload = async (value?: any) => {
    if (!value) {
      message.error("未找到文件内容")
      return
    }
    getFileUrl(value)
  }
  const accountColumns = [
    {
      title: "文件类型",
      dataIndex: "type",
    },
    {
      title: "文件名称",
      dataIndex: "name",
    },
    {
      title: "上传时间",
      dataIndex: "time",
    },
    {
      title: "操作",
      dataIndex: "operation",
      fixed: "right",
      render: (text: any, record: any) => {
        return (
          <Space>
            <a
              onClick={async () => {
                if (!record?.fileId) {
                  message.error("未找到文件内容")
                  return
                }
                let resultData: any = await checkFile([record?.fileId])
                let newResultData = resultData?.map((item, index) => {
                  return {
                    thumbnail: item,
                    original: item,
                    description: "图片" + (index + 1),
                  }
                })
                setPdfModal({
                  visible: true,
                  urls: newResultData,
                })
              }}
            >
              预览
            </a>
            <a onClick={() => handleDownload(record?.fileId)}>下载</a>
          </Space>
        )
      },
    },
  ]

  useEffect(() => {
    if (searchParams?.id) getInfo(searchParams?.id)
  }, [searchParams])

  return (
    <DetailPageContainer>
      <DetailPageHeader
        title={
          <Space>
            <Button
              onClick={() => navigate("/finance/consent")}
              icon={<RollbackOutlined />}
              style={{ color: "#1890FF" }}
            >
              返回
            </Button>
            <span style={{ paddingLeft: 4 }}>供应商准入详情</span>
          </Space>
        }
        breadCrumb={[
          { label: "供应链金融", path: "" },
          { label: "供应商准入", path: "" },
          { label: "详情", path: "" },
        ]}
        rightContent={<></>}
      ></DetailPageHeader>
      <DetailPageContent>
        <Card title="基础信息" style={{ marginTop: 16 }}>
          <Row>
            <Col span={8}>
              <div className="form_item">
                <span className="form_item_label">企业名称：</span>
                <div>{info?.name}</div>
              </div>
            </Col>
            <Col span={8}>
              <div className="form_item">
                <span className="form_item_label">企业类型：</span>
                <div>{info?.typeDesc}</div>
              </div>
            </Col>
            <Col span={8}>
              <div className="form_item">
                <span className="form_item_label">统一社会信用代码：</span>
                <div>{info?.taxNo}</div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col span={8}>
              <div className="form_item">
                <span className="form_item_label">营业执照注册日期：</span>
                <div>{info?.licenseRegTime}</div>
              </div>
            </Col>
            <Col span={8}>
              <div className="form_item">
                <span className="form_item_label">企业注册地：</span>
                <div>{info?.regAddress}</div>
              </div>
            </Col>
            <Col span={8}>
              <div className="form_item">
                <span className="form_item_label">企业联系人：</span>
                <div>{info?.legalName}</div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col span={8}>
              <div className="form_item">
                <span className="form_item_label">联系电话：</span>
                <div>{info?.legalPhone}</div>
              </div>
            </Col>
            <Col span={8}>
              <div className="form_item">
                <span className="form_item_label">通讯地址：</span>
                <div>{info?.contactAddress}</div>
              </div>
            </Col>
          </Row>
        </Card>
        <Card title="经营者信息" style={{ marginTop: 16 }}>
          <Row>
            <Col span={8}>
              <div className="form_item">
                <span className="form_item_label">姓名：</span>
                <div>{info?.legalName}</div>
              </div>
            </Col>
            <Col span={8}>
              <div className="form_item">
                <span className="form_item_label">身份证号：</span>
                <div>{info?.legalCertNo}</div>
              </div>
            </Col>
            <Col span={8}>
              <div className="form_item">
                <span className="form_item_label">手机号：</span>
                <div>{info?.legalPhone}</div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col span={8}>
              <div className="form_item">
                <span className="form_item_label">身份证有效期：</span>
                <div>{(info?.certBeginDate || "") + "-" + (info?.certEndDate || "")}</div>
              </div>
            </Col>
            <Col span={8}>
              <div className="form_item">
                <span className="form_item_label">身份证地址：</span>
                <div>{info?.regAddr}</div>
              </div>
            </Col>
          </Row>
        </Card>
        <Card title="附件信息" style={{ marginTop: 16 }}>
          <TableCom
            columns={accountColumns}
            dataSource={resultFile}
            total={0}
            pagination={false}
            scroll={{
              x: true,
            }}
          ></TableCom>
        </Card>
        <Modal
          open={pdfModal.visible}
          width={1000}
          footer={null}
          onCancel={() => setPdfModal({ visible: false, urls: [] })}
        >
          <div className="preview-image">
            <PreviewFile flag={true} preview={true} imageData={pdfModal?.urls} />
          </div>
        </Modal>
      </DetailPageContent>
    </DetailPageContainer>
  )
}

export default supplierAccessDetails
