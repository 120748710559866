/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef, useMemo } from "react"
import { Button, message, Space, Popconfirm, Input, Modal, Form, Row, Col } from "antd"
import { useNavigate, Link } from "react-router-dom"
import columnsP from "./columns"
import SKTable from "@src/components/SkTable"
import { DetailPageContent } from "@src/components/DetailPageComponents/Content"
import { DetailPageHeader } from "@src/components/DetailPageComponents/Header"
import { DetailPageContainer } from "@src/components/DetailPageComponents/PageContainer"
import useTableTitle from "@src/hooks/useTableTitle"
import Access from "@src/components/Access"
import { useAccess } from "@src/contents/useAccess"
import { RexNumber, RexInvoice } from "@src/constant/RegExp"
import { debounce } from "@src/utils/RegExp"
import exportToExcel from "../../../utils/exportToExcel"
import { DownOutlined, UpOutlined, SearchOutlined } from "@ant-design/icons"
import {
  getInvoiceCategory,
  getInvoiceCategoryTaxName,
  getInvoiceCategoryAdd,
  getInvoiceCategoryDelete,
} from "../service"
import "./index.scss"
import { inputDefaultRules } from "@src/utils/RegExp"

let keywords: string = ""
let invoiceCategoryShort: string = ""
const TradingScheme = () => {
  const tableRef = useRef<any>(null)
  const navigate = useNavigate()
  const [form] = Form.useForm()
  const access = useAccess()
  const [exportList, setExportList] = useState([])
  const [numList, setNumList] = useState<Number>(0)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [states, setStates] = useState<Boolean>(false)
  const [invoiceCategoryList, setInvoiceCategoryList] = useState([])
  const [newInvoiceCategoryShort, setNewInvoiceCategoryShort] = useState("全部分类")

  const handleRequestData = async (params: any) => {
    const { current, pageSize, ...rest } = params
    const res: any = await getInvoiceCategory({
      taxCategoryAbbreviation: invoiceCategoryShort == "全部分类" ? "" : invoiceCategoryShort,
      keywords: keywords,
      current,
      size: pageSize,
      ...rest,
    })
    setNumList(res.data.total)
    setExportList(res?.data.records)
    return {
      data: res?.data.records,
      total: res?.data.total,
    }
  }
  const handGetInvoiceCategoryTaxName = async () => {
    const result = await getInvoiceCategoryTaxName({ size: -1 })
    if (result.code == 200) {
      result.data.unshift({
        taxCategoryAbbreviation: "全部分类",
        count: result.data.reduce(
          (sum: any, certificate: { count: any }) => (sum += certificate.count),
          0
        ),
      })
      console.log(result.data, "result.data")
      setInvoiceCategoryList(result.data)
    }
  }
  const handDelete = async (id) => {
    const res: any = await getInvoiceCategoryDelete(id)
    if (res.code == 200) {
      message.success("删除成功")
      handGetInvoiceCategoryTaxName()
      upDataTable()
    } else {
      message.error(res.message)
    }
  }
  const columns: any = [
    ...columnsP,
    {
      title: "操作",
      dataIndex: "operation",
      width: 100,
      fixed: "right",
      render: (_: any, record: any) => (
        // <Access accessible={access.op_platform_invoice_feature_delete}>
        <Popconfirm
          title="确定要删除吗？"
          onConfirm={() => {
            handDelete(record.id)
          }}
        >
          <Button type="link">删除</Button>
        </Popconfirm>
        // </Access>
      ),
    },
  ]
  const onFinish = async (values: any) => {
    console.log(values, "values")
    let params = {
      ...values,
    }
    const result: any = await getInvoiceCategoryAdd(params)
    if (result.code == 200) {
      message.success("添加成功")
      handGetInvoiceCategoryTaxName()
      handleCancel()
      upDataTable()
    } else {
      message.error(result.message)
    }
  }
  const handleCancel = () => {
    form.resetFields()
    setIsModalOpen(false)
  }
  const checkListItem = (value: any) => {
    invoiceCategoryShort = value
    setNewInvoiceCategoryShort(value)
    upDataTable()
  }
  const upDataTable = () => {
    tableRef.current.upData()
  }
  useEffect(() => {
    handGetInvoiceCategoryTaxName()
  }, [])
  const handInvoiceCategoryList = useMemo(() => {
    let newData = []
    if (states) {
      newData = invoiceCategoryList
    } else {
      newData = invoiceCategoryList.slice(0, 7)
    }
    return (
      <div className="user_serch" style={{ maxHeight: states ? 200 : 30 }}>
        所属类目：{" "}
        {newData?.map((item: any, index: React.Key | null | undefined) => (
          <div
            onClick={() => checkListItem(item.taxCategoryAbbreviation)}
            className={`${"default_item"} ${
              newInvoiceCategoryShort == item.taxCategoryAbbreviation
                ? "activeListItem"
                : "listItem"
            }`}
            key={index}
          >
            {item.taxCategoryAbbreviation} ({item.count})
          </div>
        ))}
      </div>
    )
  }, [invoiceCategoryList, states, invoiceCategoryShort])
  return (
    <DetailPageContainer>
      <DetailPageHeader
        title={
          <Space>
            <span>开票名称配置</span>
          </Space>
        }
        breadCrumb={[
          { label: "平台设置", path: "" },
          { label: "开票名称配置", path: "" },
        ]}
        rightContent={""}
      ></DetailPageHeader>
      <DetailPageContent>
        <div className="user_serch_invoice">
          <div style={{ display: "flex", alignItems: "center" }}>
            {handInvoiceCategoryList}
            <div className="user_serch_icon">
              <a
                href="#"
                style={{ display: invoiceCategoryList.length > 6 ? "" : "none" }}
                onClick={() => {
                  setStates(!states)
                }}
              >
                {states ? (
                  <span>
                    收起
                    <UpOutlined style={{ paddingLeft: 5 }} />
                  </span>
                ) : (
                  <span>
                    展开
                    <DownOutlined style={{ paddingLeft: 5 }} />
                  </span>
                )}
              </a>
            </div>
          </div>

          <div className="line"></div>
          <div>
            搜索：
            <Input
              style={{ width: 320 }}
              placeholder="输入发票项目名称，税收分类编码搜索"
              onChange={debounce((value) => {
                ;(keywords = value?.target?.value?.trim()), upDataTable()
              }, 300)}
              allowClear
              suffix={<SearchOutlined style={{ color: "rgba(217, 217, 217, 1)" }} />}
            />
          </div>
        </div>

        <SKTable
          title={`开票名称配置列表/${numList}`}
          ref={tableRef}
          toolbarRender={
            <>
              <Space align="end">
                {/* <Access accessible={access.op_platform_invoice_feature_add}> */}
                <Button type="primary" onClick={() => setIsModalOpen(true)}>
                  新增
                </Button>
                {/* </Access> */}
                {/* <Access accessible={access.op_platform_invoice_feature_exprot}> */}
                <Button onClick={() => exportToExcel(exportList, "开票名称配置", columnsP)}>
                  导出
                </Button>
                {/* </Access> */}
              </Space>
            </>
          }
          pagination={{
            pageSize: 20,
            showQuickJumper: true,
            showTotal(total, range) {
              return `总共${total}条`
            },
          }}
          request={handleRequestData}
          pageChangeWithRequest={true}
          columns={columns}
          searchFlag={false}
          // search={{
          //   mode: "simple",
          // }}
        ></SKTable>
        <Modal
          open={isModalOpen}
          title="新增开票名称"
          onCancel={handleCancel}
          centered
          width={600}
          footer={null}
        >
          <Form
            form={form}
            name="basic"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            style={{ maxWidth: 600 }}
            onFinish={onFinish}
          >
            <Row style={{ paddingTop: 20 }}>
              <Col span={20}>
                <Form.Item
                  label="发票项目名称"
                  name="itemName"
                  rules={[
                    { required: true },
                    { pattern: RexInvoice, message: "验证格式，必须为*xxx*xxx" },
                    {
                      validator: async (rule: any, value: string) =>
                        inputDefaultRules(value, "发票项目名称", 50), //特殊字符
                    },
                  ]}
                >
                  <Input placeholder="格式为：*税收分类简称*商品名称" max={50} />
                </Form.Item>
              </Col>
              <Col span={20} style={{ paddingTop: 4, marginBottom: 20 }}>
                <Form.Item
                  label="税收分类编码"
                  name="taxCategoryCode"
                  rules={[{ required: true }, { pattern: RexNumber, message: "请输入数字" }]}
                >
                  <Input placeholder="请输入" min={0} style={{ width: "100%" }} />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item wrapperCol={{ span: 16 }} style={{ borderTop: "1px solid #f0f0f0" }}>
              <div
                className="form_footer"
                style={{ display: "flex", marginBottom: 10, paddingTop: 10 }}
              >
                <div style={{ display: "flex", flex: 1, textAlign: "right", marginLeft: "110%" }}>
                  <Button
                    style={{ marginRight: "12px" }}
                    onClick={(e) => {
                      handleCancel()
                    }}
                  >
                    取消
                  </Button>
                  <Button type="primary" htmlType="submit">
                    确定
                  </Button>
                </div>
              </div>
            </Form.Item>
          </Form>
        </Modal>
      </DetailPageContent>
    </DetailPageContainer>
  )
}

export default TradingScheme
