/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useEffect, useRef } from "react"
import { Button, message, Space, Badge } from "antd"
import { useNavigate } from "react-router-dom"
import AddModal from "./UserModal/AddModal"
import { DetailPageContent } from "@src/components/DetailPageComponents/Content"
import { DetailPageHeader } from "@src/components/DetailPageComponents/Header"
import { DetailPageContainer } from "@src/components/DetailPageComponents/PageContainer"
import "./index.scss"
import SKTable from "@src/components/SkTable"
import schema from "./schema"
import columnsP from "./columns"
import { getFinanceList } from "../service"
import Access from "@src/components/Access"
import { useAccess } from "@src/contents/useAccess"

const financingOrder = () => {
  const tableRef = useRef<any>(null)
  const navigate = useNavigate()
  const access = useAccess()
  const columns: any = [
    ...columnsP,
    {
      title: "操作",
      dataIndex: "operation",
      fixed: "right",
      render: (text: any, record: any) => {
        return (
          <div>
            <Button type="link" onClick={() => navigate(`/finance/order/${record?.id}`)}>
              详情
            </Button>
          </div>
        )
      },
    },
  ]
  const handleRequestData = async (params: any) => {
    const {
      status,
      keywords,
      current,
      createTimeStart,
      createTimeEnd,
      tranDateStart,
      tranDateEnd,
      pageSize,
      ...rest
    } = params
    const res: any = await getFinanceList({
      keywords: keywords?.trim(),
      current,
      status,
      createTimeStart: createTimeStart && createTimeStart.format("YYYY-MM-DD"),
      createTimeEnd: createTimeEnd && createTimeEnd.format("YYYY-MM-DD"),
      tranDateStart: tranDateStart && tranDateStart.format("YYYY-MM-DD"),
      tranDateEnd: tranDateEnd && tranDateEnd.format("YYYY-MM-DD"),
      size: pageSize,
      ...rest,
    })
    return {
      data: res.data.records,
      total: res.data.total,
    }
  }
  const upDataTable = () => {
    tableRef.current.upData()
  }
  return (
    <DetailPageContainer>
      <DetailPageHeader
        title={
          <Space>
            <span>融资订单</span>
          </Space>
        }
        breadCrumb={[
          { label: "供应链金融", path: "" },
          { label: "融资订单", path: "" },
        ]}
        rightContent={""}
      ></DetailPageHeader>
      <DetailPageContent>
        <SKTable
          title={"融资订单列表"}
          ref={tableRef}
          toolbarRender={<></>}
          pagination={{
            pageSize: 10,
            showQuickJumper: true,
            showTotal(total, range) {
              return `总共${total}条`
            },
          }}
          request={handleRequestData}
          pageChangeWithRequest={true}
          columns={columns}
          searchFlag={true}
          search={{
            mode: "simple",
            schema: schema,
          }}
        ></SKTable>
      </DetailPageContent>
    </DetailPageContainer>
  )
}
export default financingOrder
