/* eslint-disable react/display-name */
import { Button, Descriptions, Space, message, Dropdown, Modal, Form, Steps, Input } from "antd"
import React, { memo } from "react"
import { OrderDetailContext, useOrderDetail } from "./useOrderDetail"
import { OrderBasicInfo } from "./basicInfo"
import { OrderContractInfo } from "./contractInfo"
import { OrderPaymentInfo } from "./PaymentInfo"
import { LogisticsInfo } from "./logisticsInfo"
import { OrderInvoiceInfo } from "./InvoiceInfo"
import "./index.scss"
import { dateWithFallback } from "@/utils/utils"
import {
  ContractStatusEnum,
  OrderPaymentStatusEnum,
  TextStyle_OrderContractStatusEnum,
} from "@/constant/order"
import { DetailPageContent } from "@src/components/DetailPageComponents/Content"
import { DetailPageHeader } from "@src/components/DetailPageComponents/Header"
import { DetailPageContainer } from "@src/components/DetailPageComponents/PageContainer"
import { TagCustomStyle } from "@src/components/TagCustomStyle"
import {
  OrderInvoiceStatusEnum,
  OrderLogisticsStatusEnum,
  TextStyle_ContractStatusEnum,
  examineStatusEnum,
} from "../../../../../constant/order"
import { checkValueToContinue, confirmToContinue } from "../../../../../utils/utils"
import { postRevokeOrder } from "../../../promises"
import Access from "@src/components/Access"
import { useAccess } from "@src/contents/useAccess"
import { DownOutlined } from "@ant-design/icons"
import ModalForm from "../../../../../components/ModalForm"
import {
  orderApprovalPass,
  orderApprovalRefuse,
  orderApprovalStart,
} from "../../../../../services/api/orderApproval"
import { ReviewLog } from "./reviewLog"
import { useLoading } from "../../../../../hooks/useLoading"
const defaultProps = {}
type props = {}
const prefix = "new-order-detail-page"
const classWithPrefix = (classname: string) => {
  return [prefix, classname].join("-")
}
export type NewPurchaseOrderProps = props & React.HTMLAttributes<HTMLDivElement>
export const NewPurchaseOrder: React.FC<React.PropsWithChildren<NewPurchaseOrderProps>> = memo(
  () => {
    const orderDetail = useOrderDetail()
    const access = useAccess()

    console.log(orderDetail, 11111111)
    const revokeOrder = async () => {
      await confirmToContinue({
        title: "提示",
        content: "是否撤销该订单？",
      })
      await checkValueToContinue(orderDetail.data.id!, "订单ID不存在")
      await postRevokeOrder(orderDetail.data.id!)
      message.success("操作成功")
      history.go(-1)
    }
    const [approvalStart, startLoading] = useLoading(async () => {
      return await confirmToContinue({ title: "提示", content: "是否开始审核？" })
        .then(() => orderApprovalStart(orderDetail.data.id!))
        .then(() => {
          message.success("操作成功")
          orderDetail.refresh()
        })
    })

    const [approvalPass, passLoading] = useLoading(async () => {
      await confirmToContinue({ title: "提示", content: "是否审核通过该订单？" })
      await orderApprovalPass(orderDetail.data.id!)
      message.success("操作成功")
      await orderDetail.refresh()
    })
    const [approvalRefuse, refuseLoading] = useLoading(async (values) => {
      orderApprovalRefuse(orderDetail.data.id!, values.reason).then(() => {
        message.success("操作成功")
        orderDetail.refresh()
      })
    })
    const approvalUIMap: { [key: string]: () => React.ReactNode[] } = {
      NO_REVIEW: () => [],
      PENDING: () => [
        <Access
          key={"start-approval"}
          accessible={access.op_entity_order_feture_detail_start_approval}
        >
          <Button loading={startLoading} type={"primary"} onClick={approvalStart}>
            开始审核
          </Button>
        </Access>,
      ],
      REVIEWING: () => [
        <Access
          key={"pass-approval"}
          accessible={access.op_entity_order_feture_detail_pass_approval}
        >
          <Button loading={passLoading} type={"primary"} onClick={approvalPass}>
            审核通过
          </Button>
        </Access>,
        <Access
          key={"refuse-approval"}
          accessible={access.op_entity_order_feture_detail_refuse_approval}
        >
          <ModalForm<{ reason: string }>
            key={"refuse-approval"}
            disabled={refuseLoading}
            onFinish={approvalRefuse}
            title={"审核驳回原因"}
            trigger={
              <Button loading={refuseLoading} type={"primary"} danger>
                审核驳回
              </Button>
            }
            // modalProps={{ style: { paddingTop: 24, marginRight: 40 } }}
          >
            <div style={{ paddingTop: 24, marginRight: 40 }}>
              <Form.Item
                labelCol={{ span: 8 }}
                rules={[{ required: true }]}
                label={"请输入驳回原因"}
                name={"reason"}
              >
                <Input.TextArea maxLength={40} placeholder={"请输入驳回原因"} />
              </Form.Item>
            </div>
          </ModalForm>
        </Access>,
      ],
      REVIEWED: () => [],
      REFUSED: () => [],
    }
    console.log(orderDetail?.data, "orderDetail?.data")
    return (
      <OrderDetailContext.Provider value={orderDetail}>
        <DetailPageContainer>
          <DetailPageHeader
            title={
              <Space>
                <Button onClick={() => history.go(-1)}>返回</Button>
                <span>采购订单：{orderDetail.data?.id}</span>{" "}
              </Space>
            }
            breadCrumb={[{ label: "订单管理" }, { label: "采购订单" }, { label: "详情" }]}
            rightContent={
              <Space size="large" direction="vertical">
                <div style={{ textAlign: "end" }}>
                  <Space>
                    {approvalUIMap[orderDetail.data.reviewStatus as any]?.()}
                    <span
                      hidden={
                        !(
                          orderDetail.data.paymentStatus === "PENDING" &&
                          orderDetail.data.invoiceStatus === "PENDING"
                        )
                      }
                    >
                      <Access accessible={access.op_entity_order_feture_detail_cancel}>
                        <Button onClick={revokeOrder}>撤销</Button>
                      </Access>
                    </span>
                  </Space>
                </div>
                <Space size="large">
                  <div className={classWithPrefix("status-box")}>
                    <div className={classWithPrefix("status-label")}>合同签署</div>
                    <TagCustomStyle
                      noBGColor
                      size="large"
                      // @ts-ignore
                      status={ContractStatusEnum[orderDetail.data?.contractStatus]?.status}
                      text={ContractStatusEnum[orderDetail.data.contractStatus]?.text}
                    />
                  </div>
                  <div className={classWithPrefix("status-box")}>
                    <div className={classWithPrefix("status-label")}>物流状态</div>
                    <TagCustomStyle
                      noBGColor
                      size="large"
                      {...OrderLogisticsStatusEnum[orderDetail.data.logisticsName]}
                      // status={orderDetail.data.logisticsName === "已完成" ? "success" : "error"}
                      // text={orderDetail.data.logisticsName || "未完成"}
                    />
                  </div>
                  <div className={classWithPrefix("status-box")}>
                    <div className={classWithPrefix("status-label")}>支付状态</div>
                    <TagCustomStyle
                      noBGColor
                      size="large"
                      {...OrderPaymentStatusEnum[
                        orderDetail.data.paymentStatus ? orderDetail.data.paymentStatus : "PENDING"
                      ]}
                    />
                  </div>
                  <div className={classWithPrefix("status-box")}>
                    <div className={classWithPrefix("status-label")}>开票状态</div>
                    <TagCustomStyle
                      noBGColor
                      size="large"
                      {...OrderInvoiceStatusEnum[
                        orderDetail.data.invoiceStatus ? orderDetail.data.invoiceStatus : "PENDING"
                      ]}
                    />
                  </div>
                  {/* 审核状态字段未提供 1.2.9  */}
                  <div className={classWithPrefix("status-box")}>
                    <div className={classWithPrefix("status-label")}>审核状态</div>
                    <Dropdown
                      menu={{
                        items: [
                          {
                            label: (
                              <ModalForm
                                modalProps={{ okButtonProps: { hidden: true }, cancelText: "关闭" }}
                                title={"审核记录"}
                                trigger={<div>审核记录</div>}
                              >
                                <ReviewLog
                                  logData={orderDetail?.data?.auditLogs}
                                  orderId={orderDetail.data.id}
                                />
                              </ModalForm>
                            ),
                            key: "1",
                          },
                          {
                            label: (
                              <ModalForm
                                modalProps={{ okButtonProps: { hidden: true }, cancelText: "关闭" }}
                                title={"审核标准"}
                                trigger={<div>查看审核标准</div>}
                              >
                                <Form.Item label={"开票审核要求"}>
                                  <span>
                                    {
                                      orderDetail.tradingSchemeDetailsService.data?.data
                                        ?.applyInvoiceConfig?.approvalInvoice
                                    }
                                  </span>
                                </Form.Item>
                              </ModalForm>
                            ),
                            key: "2",
                          },
                        ],
                      }}
                    >
                      <Space>
                        <TagCustomStyle
                          noBGColor
                          size="large"
                          {...examineStatusEnum[
                            orderDetail.data.reviewStatus
                              ? orderDetail.data.reviewStatus
                              : "NO_REVIEW"
                          ]}
                        />
                        <DownOutlined />
                      </Space>
                    </Dropdown>
                  </div>
                </Space>
              </Space>
            }
          >
            <Descriptions column={2}>
              <Descriptions.Item label="创建时间">
                {dateWithFallback(orderDetail.data?.createTime)?.format("YYYY-MM-DD HH:mm:ss")}
              </Descriptions.Item>
              <Descriptions.Item label="来源">{orderDetail.data.channelName}</Descriptions.Item>
            </Descriptions>
          </DetailPageHeader>
          <DetailPageContent>
            <OrderBasicInfo />
            <OrderContractInfo />
            <OrderPaymentInfo />
            <LogisticsInfo />
            <OrderInvoiceInfo />
          </DetailPageContent>
        </DetailPageContainer>
      </OrderDetailContext.Provider>
    )
  }
)
NewPurchaseOrder.defaultProps = defaultProps

export default NewPurchaseOrder
