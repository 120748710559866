/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useMemo, useRef } from "react"
import { Button, Row, Col, Card, Space, Form } from "antd"
import { useParams, useNavigate } from "react-router-dom"
import { DetailPageContent } from "@src/components/DetailPageComponents/Content"
import { DetailPageHeader } from "@src/components/DetailPageComponents/Header"
import { DetailPageContainer } from "@src/components/DetailPageComponents/PageContainer"
import Access from "@src/components/Access"
import { useAccess } from "@src/contents/useAccess"
import { RollbackOutlined } from "@ant-design/icons"
import {} from "../columns"
import { getLoanCompanyDetails } from "../../service"
import "./index.scss"
import { columnsPublic } from "./columns"
import { ActionType, EditableProTable } from "@ant-design/pro-table"

type DataSourceType = {
  id: React.Key
  title?: string
  readonly?: string
  decs?: string
  state?: string
  created_at?: number
  update_at?: number
  children?: DataSourceType[]
}
const defaultData: DataSourceType[] = [
  {
    id: "624748504",
    title: "活动名称一",
    decs: "这个活动真好玩",
  },
  {
    id: "624691229",
    title: "活动名称二",
    decs: "这个活动真好玩",
  },
]
const CoreEnterpriseDetails = () => {
  const navigate = useNavigate()
  const searchParams = useParams()
  const access = useAccess()
  const [form] = Form.useForm()
  const actionRef = useRef<ActionType>()
  const [editableKeys, setEditableRowKeys] = useState<React.Key[]>([])
  const [dataSource, setDataSource] = useState<readonly any[]>([])
  const [info, setInfo] = useState<any>({})
  const getInfo = async (id: any) => {
    if (!id) return
    const res = await getLoanCompanyDetails(id)
    if (res?.code === 200) {
      setInfo(res?.data)
    }
  }

  const columns: any[] = [
    ...columnsPublic,
    {
      title: "操作",
      valueType: "option",
      // eslint-disable-next-line no-sparse-arrays
      render: (text, record, recordIndex, action) => [
        <a
          key="editable"
          onClick={() => {
            action?.startEditable?.(record.id)
          }}
        >
          编辑
        </a>,
        <a
          key="delete"
          onClick={() => {
            setDataSource(dataSource.filter((item) => item.id !== record.id))
          }}
        >
          删除
        </a>,
        ,
      ],
    },
  ]
  useEffect(() => {
    getInfo(searchParams?.id)
  }, [searchParams])

  return (
    <DetailPageContainer>
      <DetailPageHeader
        title={
          <Space>
            <Button
              onClick={() => navigate("/finance/enterprise")}
              icon={<RollbackOutlined />}
              style={{ color: "#1890FF" }}
            >
              返回
            </Button>
            <span style={{ paddingLeft: 4 }}>核心企业准入详情</span>
          </Space>
        }
        breadCrumb={[
          { label: "供应链金融", path: "" },
          { label: "核心企业准人", path: "" },
          { label: "详情", path: "" },
        ]}
        rightContent={<></>}
      ></DetailPageHeader>
      <DetailPageContent>
        <Card title="基础信息" style={{ marginTop: 16 }}>
          <Row>
            <Col span={8}>
              <div className="form_item">
                <span className="form_item_label">企业名称：</span>
                <div>{info?.name}</div>
              </div>
            </Col>
            <Col span={8}>
              <div className="form_item">
                <span className="form_item_label">核心企业证件号：</span>
                <div>{info?.certNo}</div>
              </div>
            </Col>
            <Col span={8}>
              <div className="form_item">
                <span className="form_item_label">法人身份证号：</span>
                <div>{info?.legalCertNo}</div>
              </div>
            </Col>
          </Row>
        </Card>
        {/* <Card
          title="签署人信息"
          style={{ marginTop: 16 }}
          extra={
            <Space>
              <Button
                type="primary"
                onClick={() => {
                  actionRef.current?.addEditRecord?.({
                    id: (Math.random() * 1000000).toFixed(0),
                  })
                }}
              >
                添加一行
              </Button>
            </Space>
          }
        >
          <EditableProTable<DataSourceType>
            rowKey="id"
            maxLength={5}
            scroll={{
              x: 960,
            }}
            actionRef={actionRef}
            loading={false}
            columns={columns}
            recordCreatorProps={false}
            request={async () => ({
              data: defaultData,
              total: 3,
              success: true,
            })}
            value={dataSource}
            onChange={setDataSource}
            editable={{
              editableKeys,
              onSave: async (rowKey, data, row) => {
                console.log(rowKey, data, row)
              },
              onChange: setEditableRowKeys,
              actionRender: (row, config, dom) => [dom.save, dom.cancel],
            }}
          />
        </Card> */}
      </DetailPageContent>
    </DetailPageContainer>
  )
}

export default CoreEnterpriseDetails
