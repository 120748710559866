import React, { useState, useEffect, useRef } from "react"
import { Button, message, Space, Badge } from "antd"
import SKTable from "@src/components/SkTable"
import schema from "./schema"
import { useNavigate, Link } from "react-router-dom"
import columnsP from "./columns"
import { getOperRegions } from "../service"
import useTableTitle from "@src/hooks/useTableTitle"
import { DetailPageContent } from "@src/components/DetailPageComponents/Content"
import { DetailPageHeader } from "@src/components/DetailPageComponents/Header"
import { DetailPageContainer } from "@src/components/DetailPageComponents/PageContainer"
import "./index.scss"
import Access from "@src/components/Access"
import { useAccess } from "@src/contents/useAccess"
import { getIndividualCancelList } from "./promise"
const OutSeifEmployed = () => {
  const { setTotal, tableTitle } = useTableTitle("工商税务开办列表")
  const tableRef = useRef<any>(null)
  const [numList, setNumList] = useState<Number>(0)
  const navigate = useNavigate()
  const access = useAccess()
  const init = async () => {
    const res: any = await getOperRegions({})
    if (res?.data?.records.length) {
      let options = res.data.records.map((ie) => {
        return {
          value: ie.id,
          label: ie.name,
        }
      })
      tableRef.current.setSearch("regionId", {
        props: {
          options,
        },
      })
    }
  }
  const handleRequestData = async (params: any) => {
    const {
      legalName,
      organizationName,
      regionId,
      applyNo,
      status,
      isConnectMe,
      current,
      pageSize,
      ...rest
    } = params
    const res: any = await getIndividualCancelList({
      current,
      legalName: legalName?.trim(),
      organizationName: organizationName?.trim(),
      regionId,
      applyNo: applyNo?.trim(),
      status,
      isConnectMe,
      size: pageSize,
      ...rest,
    })
    setTotal(res?.total)
    setNumList(res.data.total)
    return {
      data: res?.data.records,
      total: res?.data.total,
    }
  }
  const columns: any = [
    ...columnsP,
    {
      title: "操作",
      dataIndex: "operation",
      width: 100,
      fixed: "right",
      render: (text: any, record: any) => {
        return (
          <div className="table-operate">
            <Link to={`/agencyBusiness/outSetup/${record.id}`}>详情</Link>
          </div>
        )
      },
    },
  ]
  useEffect(() => {
    init()
  }, [])
  return (
    <DetailPageContainer>
      <DetailPageHeader
        title={
          <Space>
            <span>个体户注销申请</span>
          </Space>
        }
        breadCrumb={[
          { label: "业务代办", path: "" },
          { label: "工商税务开办", path: "" },
          { label: "个体户注销申请", path: "" },
        ]}
        rightContent={""}
      ></DetailPageHeader>
      <DetailPageContent>
        <SKTable
          // title={tableTitle}
          title={`个体户注销申请/${numList}`}
          ref={tableRef}
          toolbarRender={
            <>
              {/* <Access accessible={access.op_agencyBusiness_setup_feature_add}> */}
              <Button
                type="primary"
                className="btn-add"
                onClick={() => navigate(`/agencyBusiness/outNewLogout`)}
              >
                新增注销申请
              </Button>
              {/* </Access> */}
            </>
          }
          pagination={{
            pageSize: 10,
            showQuickJumper: true,
            showTotal(total, range) {
              return `总共${total}条`
            },
          }}
          request={handleRequestData}
          pageChangeWithRequest={true}
          columns={columns}
          searchFlag={true}
          search={{
            mode: "simple",
            schema: schema,
          }}
        ></SKTable>
      </DetailPageContent>
    </DetailPageContainer>
  )
}
export default OutSeifEmployed
