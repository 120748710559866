/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/display-name */
import { Button, Modal, Space, Form, message, Select } from "antd"
import React, { memo, useCallback, useEffect, useState } from "react"
import { DetailPageContent } from "@src/components/DetailPageComponents/Content"
import { DetailPageHeader } from "@src/components/DetailPageComponents/Header"
import { DetailPageContainer } from "@src/components/DetailPageComponents/PageContainer"
import { Switch } from "antd"
import TableCom from "../../../../../components/TableCom"
import { ColumnsType } from "antd/es/table"
import ProDescriptions from "@ant-design/pro-descriptions"
import AddAccredit from "./AddAccredit/index"
import ClosePassWord from "./closePassWord/index"
import { ProDescriptionsItemProps } from "@ant-design/pro-descriptions"
import { RexNumber } from "../../../../../../src/constant/RegExp"
import { RexChinesePhone } from "../../../../../../src/utils/RegExp"
import { useParams, useNavigate } from "react-router-dom"
// import { useOrderDetail } from "../serviceAhooks"
import Access from "@src/components/Access"
import { useAccess } from "@src/contents/useAccess"
import { handAccess } from "../../../../../components/Access/indexAccess"
import { RollbackOutlined } from "@ant-design/icons"

import {
  getUserInfo,
  getUserRolesLists,
  getAddUseoOrgs,
  getDeleteUseoOrgs,
  getPutUserListsUseoLists,
} from "../../../service"
import "./index.scss"
import { COMPANY_INFO } from "@/contents/companyInfo"
import { providerEnum } from "../../../constant"

const defaultProps = {}
type props = {}
interface DELETE {
  open: boolean
  id: string
}
type type_datatype = {
  key: string
  name: string
  job: string
  department: string
  email: string
  account: string
  role: string
  status: string
  operator: string
  id: string
  wxAccount: string
  roleIdList: any
  idNo: any
}
export type UserDetail = props & React.HTMLAttributes<HTMLDivElement>
export const NewUserDetail: React.FC<React.PropsWithChildren<UserDetail>> = memo(() => {
  const searchParams = useParams()
  const navigate = useNavigate()
  const access = useAccess()
  const authAccess: any = handAccess("op_platform_user_feature_edit")

  const [rolesList, setRolesList] = useState<any>([])
  const [userCrodList, setUserCrodList] = useState<any>([])
  const [AddAccreditFlag, setAddAccreditFlag] = useState<boolean>(false)
  const [paginationProps, setPaginationProps] = useState<any>({
    pageSize: 10,
    current: 1,
    total: 0,
  })
  // 删除参数
  const [openDelete, setOpenDelete] = useState<DELETE>({
    open: false,
    id: "",
  })
  const [switchStuas, setSwitchStuas] = useState<boolean>(false)
  const [ClosePassWordFlag, setClosePassWordFlag] = useState<boolean>(false)
  const [info, setInfo] = useState<any>({})
  const invoiceInfoColumns: ProDescriptionsItemProps<API.Entity.Info>[] = [
    {
      dataIndex: "phone",
      title: "手机号",
      formItemProps: { rules: [{ message: "请填写正确格式", pattern: RexChinesePhone }] },
    },
    {
      dataIndex: "account",
      title: "账号",
      formItemProps: { rules: [{ message: "账号不超过20位", max: 20 }] },
    },
    {
      dataIndex: "password",
      title: "密码",
      editable: false,
      formItemProps: { rules: [{ message: "请填写正确格式", pattern: RexNumber }] },
      render: (dom, entity, index, action) => {
        return (
          <div className="NewUserDetail_password">
            <div>******</div>
            <Access accessible={access.op_platform_user_feature_password}>
              <a
                onClick={() => {
                  setClosePassWordFlag(true)
                }}
              >
                重置密码
              </a>
            </Access>
          </div>
        )
      },
    },
    {
      dataIndex: "provider",
      title: "运营标识",
      render: (_, record, index, dom) => {
        const _value: any = record.provider ?? "default"
        return providerEnum[_value]?.text
      },
      renderFormItem: (item) => {
        return <Select options={COMPANY_INFO?.mark} placeholder="请选择" />
      },
    },
    {
      dataIndex: "name",
      title: "",
      className: "userDetail_name",
      formItemProps: { rules: [{ message: "不超过15位", max: 15 }] },
    },
  ]
  const getInfo = async (id: any) => {
    if (!id) return
    const res = await getUserInfo(id)
    console.log(res, "res")
    if (res?.code === 200) {
      setInfo(res?.data)
      setSwitchStuas(res.data.status)
    }
  }
  //用户授权列表
  const handUserRolesLists = async (paramsObj?: any) => {
    let params = {
      userId: searchParams?.id,
      current: paginationProps.current,
      size: paginationProps.pageSize,
      ...paramsObj,
    }
    const result: any = await getUserRolesLists(params)
    if (result.code == 200) {
      setPaginationProps({
        ...paginationProps,
        current: Number(result?.data?.current),
        pageSize: Number(result?.data?.size),
        total: result?.data?.total,
      })
      let resultData = result.data.records.map((ie: any) => {
        return {
          application: ie?.application?.name,
          organization: ie?.organization?.name,
          role: ie?.role?.name,
          id: ie.id,
        }
      })
      setRolesList(resultData)
    }
  }
  const onChanges = (params: any) => {
    setPaginationProps({
      ...paginationProps,
      ...params,
    })
    handUserRolesLists({ size: params.pageSize, current: params.current })
  }
  //删除
  const handDeleterole = async () => {
    const result: any = await getDeleteUseoOrgs(openDelete.id)
    if (result.code == 200) {
      message.success("删除成功")
      setOpenDelete({ open: false, id: "" })
      handUserRolesLists()
    } else {
      message.error(result.data || "删除失败")
    }
  }
  const columns: ColumnsType<type_datatype> = [
    {
      title: "所属组织",
      dataIndex: "organization",
      key: "organization",
    },
    {
      title: "应用",
      dataIndex: "application",
      key: "application",
    },

    {
      title: "角色",
      dataIndex: "role",
      key: "role",
    },
    {
      title: "操作",
      key: "operator",
      render: (_, record) => (
        <div>
          <Access accessible={access.op_platform_user_feature_deleteAccredit}>
            <Button
              type="link"
              onClick={() => {
                setOpenDelete({
                  open: true,
                  id: record.id,
                })
              }}
            >
              删除
            </Button>
          </Access>
        </div>
      ),
    },
  ]
  const onChange = (checked: boolean) => {
    setSwitchStuas(checked)
    handUserEdit(userCrodList, checked)
  }
  const handUserEdit = useCallback(async (newInfo: any, checked) => {
    console.log(searchParams.id)
    let params = {
      name: newInfo?.name,
      phone: newInfo?.phone,
      account: newInfo?.account,
      password: newInfo?.password,
      status: checked,
      id: searchParams?.id,
      provider: newInfo?.provider,
    }
    const res: any = await getPutUserListsUseoLists(params)
    if (res.code == 200) {
      getInfo(searchParams?.id)
      message.success("修改成功")
    }
  }, [])
  useEffect(() => {
    getInfo(searchParams?.id)
    handUserRolesLists()
  }, [])
  return (
    <DetailPageContainer>
      <DetailPageHeader
        title={
          <Space>
            <Button
              onClick={() => navigate("/platformSetup/user")}
              className="userDetail_bark"
              icon={<RollbackOutlined />}
              style={{ color: "#1890FF" }}
            >
              返回
            </Button>
            {/* <span>张三</span> */}
          </Space>
        }
        breadCrumb={[{ label: "用户管理" }, { label: "详情" }]}
        rightContent={
          <div style={{ display: "flex", alignItems: "center" }}>
            <p style={{ width: 95, marginTop: 14 }}>用户状态：</p>
            <Switch
              checked={switchStuas}
              checkedChildren="启用"
              unCheckedChildren="禁用"
              onChange={onChange}
              disabled={!authAccess}
            />
          </div>
        }
      ></DetailPageHeader>
      <DetailPageContent>
        <div className="NewUserDetail_Content_basic">
          <div className="NewUserDetail_Content_basic_title">
            <p>基本信息</p>
          </div>
          <div className="NewUserDetail_Content_basic_input">
            <ProDescriptions
              editable={
                authAccess && {
                  onSave: async (keypath, newInfo, oriInfo) => {
                    setUserCrodList(newInfo)
                    handUserEdit(newInfo, newInfo.status)
                  },
                }
              }
              columns={invoiceInfoColumns}
              dataSource={info}
            ></ProDescriptions>
          </div>
        </div>
        <div className="NewUserDetail_Content_settlement">
          <div className="NewUserDetail_Content_settlement_title">
            <p>授权列表</p>
            <Access accessible={access.op_platform_user_feature_addAccredit}>
              <Button
                type="primary"
                onClick={() => setAddAccreditFlag(true)}
                style={{ marginTop: "-10px" }}
              >
                新建授权
              </Button>
            </Access>
          </div>
          <div className="NewUserDetail_Content_settlement_input">
            <TableCom
              columns={columns}
              dataSource={rolesList}
              scroll={{
                x: true,
              }}
              pagination={{
                ...paginationProps,
                total: paginationProps.total,
              }}
              onChange={onChanges}
            ></TableCom>
          </div>
        </div>
      </DetailPageContent>
      <AddAccredit
        isAdd={AddAccreditFlag}
        id={searchParams.id}
        addModal={() => {
          setAddAccreditFlag(false)
        }}
        cb={handUserRolesLists}
      />
      <ClosePassWord
        isAdd={ClosePassWordFlag}
        addModal={() => {
          setClosePassWordFlag(false)
        }}
        searchParams={searchParams}
        userCrodList={info}
        // cb={getUserList}
      />
      <div className="useModal-delete">
        <Modal title="删除" getContainer={false} footer={null} open={openDelete.open}>
          <p style={{ padding: "12px 0 12px 24px" }}>确定删除吗</p>
          <Form.Item wrapperCol={{ span: 16 }} style={{ borderTop: "1px solid #f0f0f0" }}>
            <div
              className="form_footer"
              style={{ display: "flex", marginBottom: 10, paddingTop: 10 }}
            >
              <div style={{ display: "flex", flex: 1, textAlign: "right", marginLeft: "105%" }}>
                <Button
                  style={{ marginRight: "12px" }}
                  onClick={(e) => {
                    setOpenDelete({ open: false, id: "" })
                  }}
                >
                  取消
                </Button>
                <Button type="primary" onClick={handDeleterole}>
                  确定
                </Button>
              </div>
            </div>
          </Form.Item>
        </Modal>
      </div>
    </DetailPageContainer>
  )
})
NewUserDetail.defaultProps = defaultProps

export default NewUserDetail
