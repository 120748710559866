import { OP_LOGISTIC, OP_TRADE, OP_USER } from "../../../api/config/servicePort"
import UtilOp from "../../../services/serviceOp"

const utilOp = UtilOp.getInstance()

// 同步新再生网订单
export const syncOrder = (values?: any) => {
  return utilOp.httpPost(OP_TRADE + "/orders/sync-order", values).then((res) => res)
}
// 同步新再生网货单
export const syncFreight = (values?: any) => {
  return utilOp
    .httpPost(OP_LOGISTIC + "/cloud-recycling/import-ep-freight", values)
    .then((res) => res)
}
// 同步新再生网组织
export const syncOrgs = (name: string) => {
  return utilOp.httpPost(OP_USER + "/sync-data/organization?tenantName=" + name).then((res) => res)
}
// 同步新再生网供应商
export const syncSupplier = (values: { tenantName: string; supplierNames: string[] }) => {
  return utilOp.httpPost(OP_USER + "/sync-data/supplier", values).then((res) => res)
}
// 同步新再生网客户
export const syncCustomer = (values: { tenantName: string; customerNames: string[] }) => {
  return utilOp.httpPost(OP_USER + "/sync-data/customer", values).then((res) => res)
}

export const getOrderStatus = (values?: any) => {
  return utilOp.httpGet(OP_TRADE + "/orders/sync-order", values).then((res) => res)
}
