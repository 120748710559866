/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from "react"
import { Button, Input, Table, Modal, Form, InputNumber, Select, Popconfirm, message } from "antd"
import { CaretRightOutlined, CaretDownOutlined, SearchOutlined } from "@ant-design/icons"
import {
  getPlatComponentsList,
  getAddPlatComponentsList,
  getEditPlatComponentsList,
  getDelePlatComponentsList,
  getPlatComponentsListTree,
  getPlatOperList,
  getEditPlatComponentsDetil,
} from "../promises"
import SearchForm from "@src/components/FormItem"
import { useParams } from "react-router-dom"
import Access from "@src/components/Access"
import { useAccess } from "@src/contents/useAccess"
import "./index.scss"
import { DetailPageContent } from "@src/components/DetailPageComponents/Content"
import { DetailPageHeader } from "@src/components/DetailPageComponents/Header"
import { DetailPageContainer } from "@src/components/DetailPageComponents/PageContainer"
const { TextArea } = Input

const layout = {
  labelCol: {
    span: 6,
  },
  wrapperCol: {
    span: 16,
  },
}
const DetailModule = (props: any) => {
  const { info } = props
  const searchParams = useParams()
  const access = useAccess()
  let listTimer = useRef<any>(null)
  const [loading, setLoading] = useState<Boolean>(false)
  const [totalList, setTotalList] = useState<any>([])
  const [resultData, setResultData] = useState<any>([]) //功能点列表
  const [pageOptions, setPageOptions] = useState<any>({ pageSize: 10, current: 1, total: 0 })
  const [addParams, setAddParams] = useState<any>({
    type: "add",
    open: false,
    id: "",
    name: "",
    rootId: "",
    deep: 1,
    code: "",
    functionIds: [],
    remark: "",
    parentId: "",
  })
  const [form] = Form.useForm()
  const [list, setList] = useState([])
  useEffect(() => {
    if (searchParams.id) {
      getLists(searchParams.id)
      getModulePoint(searchParams.id)
    }
  }, [])
  const getLists = async (obj?: any) => {
    let params = {
      id: searchParams.id,
      current: 1,
      size: 10,
      ...obj,
    }
    setLoading(true)
    const res = await getPlatComponentsListTree(params)
    setTotalList(res?.data)
    setLoading(false)
  }
  //功能点列表
  const getModulePoint = async (id) => {
    let params = {
      current: 1,
      size: 50,
    }
    const res: any = await getPlatOperList(id, params)
    if (res.code == 200) {
      let resultData = res.data.records.map((ie: any) => {
        return {
          label: ie.name,
          value: ie.id,
          code: ie.code,
        }
      })
      setResultData(resultData)
    } else {
      message.error("功能点获取失败")
    }
  }
  const onSearch = (value: string) => {
    if (listTimer.current) {
      clearTimeout(listTimer.current)
      listTimer.current = null
    }
    listTimer.current = setTimeout(() => {
      getLists(value)
    }, 700)
  }
  const formColumns = [
    // {
    //   label: "模块",
    //   dataIndex: "orderStatus",
    //   type: "Select",
    //   params: {
    //     placeholder: "请选择",
    //     options: [
    //       {
    //         label: "全部",
    //         value: null,
    //       },
    //       {
    //         label: "零散交易者",
    //         value: "SPORADIC_TRADER",
    //       },
    //     ],
    //   },
    // },
    {
      label: "搜索",
      dataIndex: "keywords",
      type: "Input",
      width: "264px",
      params: {
        suffix: <SearchOutlined style={{ color: "rgba(217, 217, 217, 1)" }} />,
        placeholder: "组件名称，组件编码搜索",
      },
    },
  ]
  const handlePage = (object: any) => {
    const startIndex = (object?.current - 1) * object?.pageSize
    const endIndex = object.current * object.pageSize
    setList(totalList.slice(startIndex, endIndex))
    setPageOptions({
      ...pageOptions,
      current: object.current,
      pageSize: object.pageSize,
    })
    getLists({
      size: object.pageSize,
      current: object.current,
    })
  }
  // 删除
  const handleDelete = async () => {
    let params = {
      applicationId: info.id,
      id: addParams.id,
    }
    const res: any = await getDelePlatComponentsList(params)
    if (res.code == 200) {
      message.success("删除成功")
      getLists({
        size: pageOptions.pageSize,
        current: pageOptions.current,
      })
      setAddParams({ open: false, type: "add" })
      form.resetFields()
    } else {
      message.error(res.message || "删除失败")
    }
  }
  const columns = [
    {
      title: "组件名称",
      dataIndex: "name",
      key: "name",
      width: "20%",
    },
    {
      title: "组件编码",
      dataIndex: "code",
      key: "code",
      width: "20%",
    },
    {
      title: "备注",
      dataIndex: "remark",
      key: "remark",
      width: "20%",
    },
    {
      title: "排序",
      dataIndex: "sort",
      key: "sort",
    },
    {
      title: "操作",
      width: "30%",
      align: "left",
      render: (record: any) => {
        console.log(record, "record")
        return (
          <div style={{ marginLeft: -15 }}>
            {record?.applicationId < 5 && (
              <Access accessible={access.op_platform_apply_feature_addModule}>
                <Button
                  type="link"
                  onClick={async () => {
                    setAddParams({
                      ...addParams,
                      open: true,
                      code: record.code,
                      functionIds: record.functionIds,
                      remark: record.remark,
                      parentId: record.id,
                      name: record?.name,
                      parentName: record?.name || "无",
                      type: "add",
                    })
                  }}
                >
                  添加子组件
                </Button>
              </Access>
            )}
            <Access accessible={access.op_platform_apply_feature_editModule}>
              <Button
                type="link"
                onClick={() => handEditPlatComponentsDetil(record)}
                style={{ marginLeft: -20 }}
              >
                详情
              </Button>
            </Access>
          </div>
        )
      },
    },
  ]
  //组件详情
  const handEditPlatComponentsDetil = async (record: any) => {
    let params = {
      applicationId: info?.id,
      id: record.id,
    }
    const res: any = await getEditPlatComponentsDetil(params)
    if (res.code == 200) {
      setAddParams({
        ...addParams,
        ...res.data,
        open: true,
        type: "edit",
      })
      form.setFieldsValue({
        ...res.data,
        functionIds: res.data.functionIds || [],
      })
    }
  }
  const expandable = {
    rowExpandable: (e: any) => {
      return true
    },
    onExpand: (record: boolean, e: any) => {
      console.log(record, e, "record, e")
    },
    expandIcon: ({
      expanded,
      onExpand,
      record,
      expandable,
    }: {
      expanded: boolean
      onExpand: (record: any, e: any) => void
      record: boolean
      expandable: any
    }) => {
      return expandable ? (
        expanded ? (
          <CaretDownOutlined onClick={(e) => onExpand(record, e)} />
        ) : (
          <CaretRightOutlined onClick={(e) => onExpand(record, e)} />
        )
      ) : null
    },
  }
  const handleOk = async () => {
    const res = await form.validateFields()
    let obj = {}
    if (res.checkList) {
      res.checkList.forEach((item: any) => {
        obj[item] = true
      })
    }
    if (addParams.type === "add") {
      // 当前是根节点新增的时候
      let params = {
        sort: res?.sort,
        code: res.code,
        functionIds: res.functionIds,
        remark: res.remark,
        ...obj,
        name: res.name,
        parentId: addParams?.parentId,
      }
      const response: any = await getAddPlatComponentsList(params, info.id)
      if (response?.code === 200) {
        setAddParams({
          name: "",
          code: "",
          functionIds: [],
          remark: "",
          open: false,
          id: "",
          type: "add",
        })
        form.resetFields()
        getLists({
          size: pageOptions.pageSize,
          current: pageOptions.current,
        })
        message.success("操作成功")
      }
    } else {
      // editCategory
      let params = {
        applicationId: info.id,
        sort: res?.sort,
        code: res.code,
        functionIds: res.functionIds,
        remark: res.remark,
        ...obj,
        name: res.name,
        parentId: addParams?.parentId,
      }
      const response: any = await getEditPlatComponentsList(addParams.id, params)
      if (response?.code === 200) {
        setAddParams({
          name: "",
          code: "",
          functionIds: [],
          remark: "",
          open: false,
          id: "",
          type: "add",
        })
        form.resetFields()
        getLists({
          size: pageOptions.pageSize,
          current: pageOptions.current,
        })
      }
    }
  }
  const filterOption = (input: string, option?: { label: string; value: string }) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
  return (
    <>
      <div className="DetailModule_list_keysWrod">
        <SearchForm formParams={formColumns} onSearch={onSearch} optionsList={null}></SearchForm>
      </div>
      <div className="DetailModule">
        <div className="DetailModule_list">
          <div className="DetailModule_list_title">
            <div>组件列表/{totalList.length}</div>
            <div>
              <Access accessible={access.op_platform_apply_feature_addModule}>
                <Button
                  type="primary"
                  onClick={() => {
                    setAddParams({ open: true, name: "", id: "", rootId: "", deep: 1, type: "add" })
                    form.setFieldValue("checkList", ["forTrade", "forPriceIndex"])
                  }}
                >
                  新增一级组件
                </Button>
              </Access>
            </div>
          </div>
          <Table
            rowKey={(record: any) => record.id}
            loading={loading}
            size="small"
            columns={columns}
            indentSize={24}
            dataSource={totalList}
            pagination={{
              current: pageOptions.current,
              pageSize: pageOptions.pageSize,
              total: pageOptions.total,
              showQuickJumper: true,
              showSizeChanger: true,
              showTotal(total, range) {
                return `总共${total}条`
              },
            }}
            onChange={handlePage}
            expandable={expandable}
          />
        </div>
        {/* 新增 */}
        <Modal
          title={addParams.type === "add" ? "新增组件" : "组件详情"}
          open={addParams.open}
          footer={false}
          onCancel={() => {
            setAddParams({ name: "", open: false, id: "", depth: 0, type: "add" })
            form.resetFields()
          }}
        >
          <Form {...layout} form={form}>
            <Form.Item label="所属应用" style={{ paddingTop: 11 }}>
              <span>{info?.name || "无"}</span>
            </Form.Item>
            <Form.Item label="父组件" className="addModule">
              <span>{addParams?.parentName || "无"}</span>
            </Form.Item>
            <Form.Item
              label="组件名称"
              name="name"
              className="addModule"
              rules={[{ required: true, message: "请输入组件名称" }]}
            >
              <Input placeholder="请输入组件名称" showCount maxLength={15}></Input>
            </Form.Item>
            <Form.Item
              label="组件编码"
              name="code"
              className="addModule"
              rules={[{ required: true, message: "请输入组件编码" }]}
            >
              <Input placeholder="请输入组件编码"></Input>
            </Form.Item>

            <Form.Item
              label="排序"
              name="sort"
              className="addModule"
              rules={[
                { required: true, message: "请输入排序" },
                {
                  pattern: /^[0-9]*[1-9][0-9]*$/,
                  message: "只能输入整数字的排序",
                },
              ]}
            >
              <InputNumber
                placeholder="数字越小排序越靠前，最小为1"
                min={1}
                style={{ width: "100%" }}
              ></InputNumber>
            </Form.Item>
            {/* <Form.Item label="所属模块" name="name" rules={[{ required: true, message: "请选择" }]}>
            <Select
              placeholder="请选择"
              style={{ width: 200 }}
              onChange={(e) => {
                console.log(e)
              }}
              options={[
                { value: "1", label: "one" },
                { value: "2", label: "two" },
              ]}
            />
          </Form.Item> */}
            <Form.Item label="功能点" name="functionIds" className="addModule">
              <Select
                mode="multiple"
                showSearch
                placeholder="请选择"
                onChange={(e) => {
                  console.log(e)
                }}
                options={resultData}
                filterOption={filterOption}
              />
            </Form.Item>
            <Form.Item label="备注" name="remark" style={{ marginBottom: 24 }}>
              <TextArea placeholder="请填写备注"></TextArea>
            </Form.Item>
            <Form.Item style={{ width: "100%", borderTop: "1px solid #f0f0f0" }}>
              <div
                style={{
                  display: "flex",
                  marginBottom: 10,
                  paddingTop: 10,
                  paddingLeft: 20,
                }}
              >
                {addParams?.type != "add" && (
                  <div style={{ width: "100px" }}>
                    <Access accessible={access.op_platform_apply_feature_deleteModule}>
                      <Popconfirm
                        title="删除"
                        style={{ width: 100 }}
                        description={`当前组件有子组件，是否同步删除子组件 若确认删除子组件，删除当前组件及其子组件?`}
                        onConfirm={handleDelete}
                        okText="确定"
                        cancelText="取消"
                      >
                        <Button danger>删除</Button>
                      </Popconfirm>
                    </Access>
                  </div>
                )}
                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    textAlign: "right",
                    marginLeft: `${addParams?.type == "add" ? "350px" : "285px"}`,
                  }}
                >
                  <Button
                    style={{ marginRight: "12px" }}
                    onClick={() => {
                      setAddParams({ name: "", open: false, id: "", depth: 0, type: "add" })
                      form.resetFields()
                    }}
                  >
                    取消
                  </Button>
                  {addParams.type === "add" ? (
                    <Button type="primary" onClick={handleOk}>
                      确定
                    </Button>
                  ) : (
                    <Access accessible={access.op_platform_apply_feature_editModule}>
                      <Button type="primary" onClick={handleOk}>
                        确定
                      </Button>
                    </Access>
                  )}
                </div>
              </div>
            </Form.Item>
          </Form>
        </Modal>
      </div>
    </>
  )
}
export default DetailModule
