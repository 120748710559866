/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef, useMemo } from "react"
import { Button, message, Space } from "antd"
import schema from "./schema"
import { useNavigate, Link } from "react-router-dom"
import columnsP from "./columns"
import SKTable from "@src/components/SkTable"
import { DetailPageContent } from "@src/components/DetailPageComponents/Content"
import { DetailPageHeader } from "@src/components/DetailPageComponents/Header"
import { DetailPageContainer } from "@src/components/DetailPageComponents/PageContainer"
import useTableTitle from "@src/hooks/useTableTitle"
import Access from "@src/components/Access"
import { useAccess } from "@src/contents/useAccess"
import ReactPlayer from "react-player"
import ModalCom from "@src/components/ModalCom"
import { getDeviceList, getIotLiveBroadcast, getIotLiveBroadcastOff } from "../promises"
import { handNewData } from "../constant"
import "./index.scss"
import VideoPlayer from "@src/components/Aliplayer/aliplayer"
let _isOpen: any = false
const TradingScheme = () => {
  const tableRef = useRef<any>(null)
  const navigate = useNavigate()
  const access = useAccess()
  const { setTotal, tableTitle } = useTableTitle("监控管理")
  const [numList, setNumList] = useState<Number>(0)
  const [isOpen, setOpen] = useState<Boolean>(false)
  const [videoUrl, setVideoUrl] = useState<any>("")
  const [param, setParam] = useState<any>(null) // 新增状态用于存储参数
  const handleRequestData = async (params: any) => {
    const { keyword, deviceCode, current, pageSize, ...rest } = params
    const res: any = await getDeviceList({
      current,
      keyword: keyword?.trim(),
      deviceCode: deviceCode?.trim(),
      types: "CAMERA",
      size: pageSize,
      ...rest,
    })
    setTotal(res?.total)
    setNumList(res.data.total)
    return {
      data: res?.data,
      total: res?.data.total || 0,
    }
  }
  const fetchMonitorUrl = async () => {
    const result = await getIotLiveBroadcast({ deviceCodes: param?.deviceCode })
    if (result?.code == 200) {
      if (result.data[0].liveAddress) {
        // if (result.data[0].taskNo) {
        const _newData = handNewData(result.data[0].expiredTime) - 10000
        console.log(_newData, "_newData")
        setVideoUrl(result.data[0].liveAddress)
        message.destroy()
        setTimeout(() => {
          fetchMonitorUrl()
        }, _newData)
      } else {
        await new Promise((resolve) => setTimeout(resolve, 3000))
        if (_isOpen) {
          fetchMonitorUrl()
        }
      }
    }
  }
  useEffect(() => {
    if (isOpen && param) {
      fetchMonitorUrl()
    }
  }, [isOpen, param])

  useEffect(() => {
    const handleUnload = async (event) => {
      //关闭浏览器 清除直播
      await getIotLiveBroadcastOff({ deviceCodes: param?.deviceCode })
    }
    window.addEventListener("unload", handleUnload)
    return () => {
      window.removeEventListener("unload", handleUnload)
    }
  }, [])
  const columns: any = [
    ...columnsP,
    {
      title: "操作",
      dataIndex: "operation",
      fixed: "right",
      render: (text: any, record: any) => {
        return (
          <a
            onClick={async () => {
              setOpen(true)
              _isOpen = true
              message.loading("获取直播中...", 0)
              setParam(record) // 更新参数状态
            }}
          >
            查看监控
          </a>
        )
      },
    },
  ]
  const handClear = async () => {
    const result = await getIotLiveBroadcastOff({ deviceCodes: param?.deviceCode })
    if (result.code == 200) {
      setOpen(false)
      _isOpen = false
      message.destroy()
      setParam(null) // 重置参数状态
    }
  }
  return (
    <DetailPageContainer>
      <DetailPageHeader
        title={
          <Space>
            <span>监控管理</span>
          </Space>
        }
        breadCrumb={[
          { label: "物联管理", path: "" },
          { label: "监控管理", path: "" },
        ]}
        rightContent={""}
      ></DetailPageHeader>
      <DetailPageContent>
        <SKTable
          title={`监控管理列表`}
          ref={tableRef}
          toolbarRender={<></>}
          pagination={{
            pageSize: 10,
            showQuickJumper: true,
            showTotal(total, range) {
              return `总共${total}条`
            },
          }}
          request={handleRequestData}
          pageChangeWithRequest={true}
          columns={columns}
          searchFlag={true}
          search={{
            mode: "simple",
            schema: schema,
          }}
        ></SKTable>
        <ModalCom
          open={isOpen}
          title="查看监控"
          onCancel={() => {
            handClear()
          }}
          centered
          width={700}
          footer={null}
        >
          <div className="react-player">
            <VideoPlayer videoUrl={videoUrl} />
          </div>

          <div style={{ width: "100%", borderTop: "1px solid #f0f0f0" }}>
            <div className="check-modal">
              <i></i>
              <div style={{ marginBottom: 10 }}>
                <Button
                  className="check-modal-button"
                  onClick={() => {
                    handClear()
                  }}
                >
                  关闭
                </Button>
              </div>
            </div>
          </div>
        </ModalCom>
      </DetailPageContent>
    </DetailPageContainer>
  )
}

export default TradingScheme
