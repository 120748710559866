import React, { useState, useRef } from "react"
import { Button, message, Space, Popconfirm } from "antd"
import Reject from "./Reject"
import { DetailPageContent } from "@src/components/DetailPageComponents/Content"
import { DetailPageHeader } from "@src/components/DetailPageComponents/Header"
import { DetailPageContainer } from "@src/components/DetailPageComponents/PageContainer"
import "./index.scss"
import SKTable from "@src/components/SkTable"
import schema from "./schema"
import columns from "./columns"
import { getCheckList } from "./service"
import Access from "@src/components/Access"
import { useAccess } from "@src/contents/useAccess"
import { delApply } from "./service"
import _ from "lodash"
import Pass from "./Pass"
import XZExport from "../../../components/XZExport"
import { downConfig } from "../../../components/XZExport/exportConfig"
/**
 * 入驻审核
 * @returns
 */
const Check = () => {
  const tableRef = useRef<any>(null)
  const access = useAccess()
  const [selectKeys, setSelectKeys] = useState([])
  const [rejectStatus, setReject] = useState<boolean>(false)
  const [passStatus, setPassStatus] = useState<boolean>(false)
  const [selectedRowKeyList, setSelectedRowKeysList] = useState<any[]>([]) //选中数据
  const [useParams, setUseParams] = useState<any>({})
  const handleRequestData = async (params: any) => {
    const {
      status,
      keywords,
      current,
      pageSize,
      applyStartTime,
      applyEndTime,
      checkEndTime,
      checkStartTime,
      ...rest
    } = params
    setUseParams(params)
    const res: any = await getCheckList({
      keywords: keywords?.trim(),
      current,
      status,
      size: pageSize,
      ...rest,
      applyStartTime: applyStartTime && applyStartTime.format("YYYY-MM-DD") + " 00:00:00",
      applyEndTime: applyEndTime && applyEndTime.format("YYYY-MM-DD") + " 23:59:59",
      checkStartTime: checkStartTime && checkStartTime.format("YYYY-MM-DD") + " 00:00:00",
      checkEndTime: checkEndTime && checkEndTime.format("YYYY-MM-DD") + " 23:59:59",
    })
    return {
      data: res?.records,
      total: res?.total,
    }
  }
  const upDataTable = () => {
    tableRef.current.upData()
  }
  const handleSelect = (item: any, flag: any) => {
    let keys: any = _.cloneDeep(selectKeys),
      values: any = _.cloneDeep(selectedRowKeyList)
    if (flag) {
      // 选中
      keys = [...selectKeys, item?.id]
      values = [...selectedRowKeyList, item]
    } else {
      // 取消
      keys = keys.filter((k: any) => k != item?.id)
      values = values.filter((k: any) => k?.id != item?.id)
    }
    keys = _.compact(_.uniq(keys))
    values = _.compact(_.unionBy(values, "id"))
    setSelectKeys(keys)
    setSelectedRowKeysList(values)
  }
  // 删除
  const delHandle = async () => {
    const res: any = await delApply(selectKeys)
    if (res.code === 200) {
      message.success("删除成功")
      upDataTable()
      setSelectKeys([])
      setSelectedRowKeysList([])
    }
  }
  return (
    <DetailPageContainer>
      <DetailPageHeader
        title={
          <Space>
            <span>入驻审核</span>
          </Space>
        }
        breadCrumb={[
          { label: "平台设置", path: "" },
          { label: "入驻审核", path: "" },
        ]}
        rightContent={""}
      ></DetailPageHeader>
      <DetailPageContent>
        <SKTable
          title={"入驻审核"}
          ref={tableRef}
          toolbarRender={
            <>
              <Button
                type="primary"
                onClick={() => {
                  if (!selectKeys.length) {
                    message.info("请选择需要审核数据")
                    return
                  } else if (
                    selectedRowKeyList
                      .map((item: any) => item?.checkStatus)
                      .includes("CHECK_REJECT") ||
                    selectedRowKeyList.map((item: any) => item?.checkStatus).includes("CHECK_PASS")
                  ) {
                    message.info("请勾选待审核数据")
                    return
                  } else {
                    setPassStatus(true)
                  }
                }}
              >
                通过并授权
              </Button>
              {/* <Access accessible={access.op_platform_check_reject}> */}
              <Button
                type="primary"
                onClick={() => {
                  if (!selectKeys.length) {
                    message.info("请选择需要审核数据")
                    return
                  } else if (
                    selectedRowKeyList
                      .map((item: any) => item?.checkStatus)
                      .includes("CHECK_REJECT") ||
                    selectedRowKeyList.map((item: any) => item?.checkStatus).includes("CHECK_PASS")
                  ) {
                    message.info("请勾选待审核数据")
                    return
                  } else {
                    setReject(true)
                  }
                }}
              >
                审核驳回
              </Button>
              {/* </Access> */}
              <XZExport
                requestParams={useParams}
                configInfo={downConfig}
                fileName={"入驻审核列表"}
              />
              <Popconfirm
                title="删除"
                style={{ width: 100 }}
                description={`确认删除吗？删除后无法恢复！`}
                onConfirm={() => {
                  if (!selectKeys.length) {
                    message.info("请选择需要审核数据")
                    return
                  } else if (
                    selectedRowKeyList
                      .map((item: any) => item?.checkStatus)
                      .includes("CHECK_REJECT") ||
                    selectedRowKeyList.map((item: any) => item?.checkStatus).includes("CHECK_PASS")
                  ) {
                    message.info("请勾选待审核数据")
                    return
                  } else {
                    delHandle()
                  }
                }}
                okText="确定"
                cancelText="取消"
              >
                <Button danger>删除</Button>
              </Popconfirm>
            </>
          }
          request={handleRequestData}
          rowSelection={{
            selectedRowKeys: selectKeys,
            onSelect: handleSelect,
            onSelectAll: (keys: any) => setSelectKeys,
            alwaysShowAlert: true,
          }}
          columns={columns}
          searchFlag={true}
          search={{
            mode: "simple",
            schema: schema,
          }}
        ></SKTable>
        <Pass
          passStatus={passStatus}
          addModal={(value: boolean) => {
            setPassStatus(value)
          }}
          rows={selectedRowKeyList}
          cb={upDataTable}
        />
        <Reject
          rejectStatus={rejectStatus}
          addModal={(value: boolean) => {
            setReject(value)
          }}
          ids={selectedRowKeyList.map((item: any) => item?.id)}
          cb={upDataTable}
        />
      </DetailPageContent>
    </DetailPageContainer>
  )
}
export default Check
