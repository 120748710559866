/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-constant-condition */
import React, { useEffect, useState, useRef } from "react"
import { Button, Input, Table, Modal, Form, Select, Popconfirm, Tag, message } from "antd"
import { CaretRightOutlined, DownOutlined, SearchOutlined } from "@ant-design/icons"
import {
  getPlatOperList,
  getAddPlatOperList,
  getEditPlatOperList,
  getDelePlatOperList,
} from "../promises"
import SearchForm from "@src/components/FormItem"
import { treeData } from "../../constant"
import Access from "@src/components/Access"
import { useAccess } from "@src/contents/useAccess"
import "./index.scss"
const { TextArea } = Input

const layout = {
  labelCol: {
    span: 6,
  },
  wrapperCol: {
    span: 13,
  },
}
const DetailModulePoint = (props: any) => {
  const { info } = props
  const access = useAccess()
  const [form] = Form.useForm()
  let listTimer = useRef<any>(null)
  const [loading, setLoading] = useState<boolean>(false)
  const [pageOptions, setPageOptions] = useState({ pageSize: 10, current: 1, total: 0 })
  //查看组件
  const [addParams, setAddParams] = useState<Common.DetailModuleInter>({
    type: "add",
    open: false,
    record: "",
  })
  const [list, setList] = useState<any>([])
  useEffect(() => {
    getLists()
  }, [])
  const getLists = async (data?: any) => {
    setLoading(true)
    let params = {
      size: 10,
      current: 1,
      ...data,
    }
    const res: any = await getPlatOperList(info?.id, params)
    if ((res.code = 200)) {
      setList(res.data.records)
      setPageOptions({
        pageSize: res.data?.size,
        current: res.data?.current,
        total: res.data?.total,
      })
      setLoading(false)
    } else {
      message.error("请求失败")
    }
  }
  const onSearch = (value: string) => {
    if (listTimer.current) {
      clearTimeout(listTimer.current)
      listTimer.current = null
    }
    listTimer.current = setTimeout(() => {
      getLists(value)
    }, 700)
  }
  const onChange = (params: any) => {
    setPageOptions({
      ...pageOptions,
      ...params,
    })
    getLists({
      size: params.pageSize,
      current: params.current,
    })
  }
  const formColumns = [
    // {
    //   label: "功能点类型",
    //   dataIndex: "orderStatus",
    //   type: "Select",
    //   params: {
    //     placeholder: "请选择",
    //     options: [
    //       {
    //         label: "全部",
    //         value: null,
    //       },
    //       {
    //         label: "零散交易者",
    //         value: "SPORADIC_TRADER",
    //       },
    //     ],
    //   },
    // },
    {
      label: "搜索",
      dataIndex: "keywords",
      type: "Input",
      width: "264px",
      params: {
        suffix: <SearchOutlined style={{ color: "rgba(217, 217, 217, 1)" }} />,
        placeholder: "功能点名称，编码搜索",
      },
    },
  ]

  // 删除
  const handleDelete = async () => {
    let params = {
      applicationId: info?.id,
      id: addParams?.record?.id,
    }
    const result: any = await getDelePlatOperList(params)
    if (result.code == 200) {
      message.success("删除成功")
      setAddParams({ open: false, type: "add" })
      getLists({
        size: pageOptions.pageSize,
        current: pageOptions.current,
      })
    } else {
      message.error(result.message || "删除失败")
    }
  }
  const columns = [
    {
      title: "功能点名称",
      dataIndex: "name",
      key: "name",
      width: "20%",
    },
    {
      title: "功能点编码",
      dataIndex: "code",
      key: "code",
      width: "20%",
    },
    {
      title: "URL",
      dataIndex: "url",
      key: "url",
    },
    {
      title: "操作类型",
      dataIndex: "operateType",
      key: "operateType",
    },
    {
      title: "更新时间",
      dataIndex: "updateTime",
      key: "updateTime",
    },
    {
      title: "操作",
      width: "30%",
      align: "left",
      render: (record: any) => {
        return (
          <div style={{ marginLeft: -15 }}>
            <Access accessible={access.op_platform_apply_feature_editPoint}>
              <Button
                type="link"
                onClick={() => {
                  setAddParams({ open: true, type: "edit", record: record })
                  form.setFieldsValue({ ...record })
                }}
              >
                详情
              </Button>
            </Access>
          </div>
        )
      },
    },
  ]
  const handleOk = async () => {
    const res = await form.validateFields()
    if (addParams.type === "add") {
      let params = {
        ...res,
        applicationId: info?.id,
      }
      console.log(res, "res")
      const response: any = await getAddPlatOperList(params)
      if (response?.code === 200) {
        setAddParams({ open: false, type: "add" })
        form.resetFields()
        getLists({
          size: pageOptions.pageSize,
          current: pageOptions.current,
        })
        message.success("添加成功")
      } else {
        message.error("添加失败")
      }
    } else {
      let params = {
        ...res,
        applicationId: info?.id,
      }
      const response: any = await getEditPlatOperList(addParams?.record?.id, params)
      if (response?.code === 200) {
        setAddParams({ open: false, type: "add" })
        form.resetFields()
        getLists({
          size: pageOptions.pageSize,
          current: pageOptions.current,
        })
        message.success("修改成功")
      } else {
        message.error("修改失败")
      }
    }
  }
  return (
    <>
      <div className="DetailModule_list_keysWrod">
        <SearchForm formParams={formColumns} onSearch={onSearch} optionsList={null}></SearchForm>
      </div>
      <div className="DetailModule">
        <div className="DetailModule_list">
          <div className="DetailModule_list_title">
            <div>功能点列表/{list.length}</div>
            <div>
              <Access accessible={access.op_platform_apply_feature_addPoint}>
                <Button
                  type="primary"
                  onClick={() => {
                    setAddParams({ open: true, type: "add" })
                  }}
                >
                  新建功能点
                </Button>
              </Access>
            </div>
          </div>
          <Table
            rowKey={(record: any) => record.id}
            loading={loading}
            size="small"
            columns={columns}
            dataSource={list}
            pagination={{
              current: Number(pageOptions.current),
              pageSize: Number(pageOptions.pageSize),
              total: pageOptions.total,
              showQuickJumper: true,
              showSizeChanger: true,
              showTotal(total, range) {
                return `总共${total}条`
              },
            }}
            onChange={onChange}
          />
        </div>

        {/* 新增 */}
        <Modal
          title={addParams.type === "add" ? "新增功能点" : "功能点详情"}
          open={addParams.open}
          footer={false}
          onCancel={() => {
            setAddParams({ name: "", open: false, id: "", depth: 0, type: "add" })
            form.resetFields()
          }}
        >
          <Form {...layout} form={form} style={{ paddingTop: 20 }}>
            <Form.Item
              label="功能点名称"
              name="name"
              rules={[{ required: true, message: "请输入功能点名称" }]}
            >
              <Input placeholder="请输入功能点名称" showCount maxLength={15}></Input>
            </Form.Item>
            <Form.Item label="功能点编码" name="code" className="addModule">
              <Input placeholder="请输入功能点编码"></Input>
            </Form.Item>
            <Form.Item label="备注" name="remark" className="addModule">
              <TextArea placeholder="请输入"></TextArea>
            </Form.Item>
            <Form.Item label="URL" name="url" className="addModule">
              <Input placeholder="请输入"></Input>
            </Form.Item>
            <Form.Item label="操作类型" name="operateType" style={{ marginBottom: 24 }}>
              <Input placeholder="请输入"></Input>
            </Form.Item>
            <Form.Item style={{ width: "100%", borderTop: "1px solid #f0f0f0" }}>
              <div
                style={{
                  display: "flex",
                  marginBottom: 10,
                  paddingTop: 10,
                  paddingLeft: 20,
                }}
              >
                {addParams?.type != "add" && (
                  <div style={{ width: "100px" }}>
                    <Access accessible={access.op_platform_apply_feature_deletePoint}>
                      <Popconfirm
                        title="删除"
                        description="当前功能点关联组件，是否删除? "
                        onConfirm={handleDelete}
                        okText="确定"
                        cancelText="取消"
                      >
                        <Button danger>删除</Button>
                      </Popconfirm>
                    </Access>
                  </div>
                )}
                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    textAlign: "right",
                    marginLeft: `${addParams?.type == "add" ? "350px" : "285px"}`,
                  }}
                >
                  <Button
                    style={{ marginRight: "12px" }}
                    onClick={() => {
                      setAddParams({ name: "", open: false, id: "", depth: 0, type: "add" })
                      form.resetFields()
                    }}
                  >
                    取消
                  </Button>
                  {addParams.type === "add" ? (
                    <Button type="primary" onClick={handleOk}>
                      确定
                    </Button>
                  ) : (
                    <Access accessible={access.op_platform_apply_feature_editPoint}>
                      <Button type="primary" onClick={handleOk}>
                        确定
                      </Button>
                    </Access>
                  )}
                </div>
              </div>
            </Form.Item>
          </Form>
        </Modal>
      </div>
    </>
  )
}
export default DetailModulePoint
