/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useMemo } from "react"
import { Button, Row, Col, Card, Space, Modal, Badge, Form, Input, message } from "antd"
import { useParams, useNavigate } from "react-router-dom"
import { DetailPageContent } from "@src/components/DetailPageComponents/Content"
import { DetailPageHeader } from "@src/components/DetailPageComponents/Header"
import { DetailPageContainer } from "@src/components/DetailPageComponents/PageContainer"
import Access from "@src/components/Access"
import { useAccess } from "@src/contents/useAccess"
import TableCom from "../../../../components/TableCom"
import { RollbackOutlined } from "@ant-design/icons"
import { getUrl } from "../../constant"
import { getFileUrl } from "@src/utils/file.ts"
import Decimal from "decimal.js"
import moment, { Moment } from "moment"

import { checkStatusEnumStatus } from "../../constant"
import { getFinanceListDetails, getTrialPayment, getTrialRepayApply } from "../../service"
import "./index.scss"

const FinancingOrderDetails = () => {
  const navigate = useNavigate()
  const [form] = Form.useForm()
  const access = useAccess()
  const searchParams: any = useParams()
  const [resultData, setResultData] = useState<any>([])
  const [repaymentModal, setRepaymentModal] = useState<any>(false)
  const [trialAmount, setTrialAmount] = useState<any>({})
  const [info, setInfo] = useState<any>({})
  const today = new Date().toISOString().split("T")[0]
  const getInfo = async (id: any) => {
    if (!id) return
    const res = await getFinanceListDetails(id)
    if (res?.code === 200) {
      setInfo(res?.data)
      if (res?.data?.financeOrderStatus == 35) {
        handTrialPayment()
      }
    }
  }
  //还款试算
  const handTrialPayment = async () => {
    let params = {
      repayType: "POFF",
      currentPeriod: 1,
      repayDate: today,
    }
    const resultData: any = await getTrialPayment(searchParams?.id, params)
    if (resultData.code == 200) {
      setTrialAmount(resultData?.data)
      form.setFieldValue("repayAmt", resultData?.data?.totalAmt)
    }
  }
  //还款申请
  const handPrimary = async () => {
    await form.validateFields()
    let value = form.getFieldsValue()
    let params = {
      id: searchParams?.id,
      repayType: "POFF",
      currentPeriod: 1,
      repayDate: today,
      repayAmt: value?.repayAmt,
      repayMode: "REPANYMENT_OFFLINE",
    }
    const resultData: any = await getTrialRepayApply(params)
    if (resultData.code == 200) {
      message.success("还款成功")
      setRepaymentModal(false)
      form.resetFields()
      getInfo(searchParams?.id)
    }
  }
  const accountColumns = [
    {
      title: "还款日期",
      dataIndex: "sort",
    },
    {
      title: "本金",
      dataIndex: "payerRole",
    },
    {
      title: "利息",
      dataIndex: "payerRole",
    },
    {
      title: "本息金额",
      dataIndex: "payerRole",
    },
    {
      title: "其他费用",
      dataIndex: "payerRole",
    },
    {
      title: "应还总额",
      dataIndex: "payerRole",
    },
    {
      title: "已还金额",
      dataIndex: "payerRole",
    },
    {
      title: "剩余应还",
      dataIndex: "payerRole",
    },
  ]

  useEffect(() => {
    if (searchParams?.id) {
      getInfo(searchParams?.id)
    }
  }, [])
  return (
    <DetailPageContainer>
      <DetailPageHeader
        title={
          <Space>
            <Button
              onClick={() => navigate("/finance/order")}
              icon={<RollbackOutlined />}
              style={{ color: "#1890FF" }}
            >
              返回
            </Button>
            <span style={{ paddingLeft: 4 }}>融资订单详情</span>
          </Space>
        }
        breadCrumb={[
          { label: "供应链金融", path: "" },
          { label: "融资订单", path: "" },
          { label: "详情", path: "" },
        ]}
        rightContent={<></>}
      ></DetailPageHeader>
      <DetailPageContent>
        <Card
          title="申请信息"
          style={{ marginTop: 16 }}
          extra={
            <Space>
              {info?.repaymentStatus == "START" ? (
                <Button type="primary">待资方确认</Button>
              ) : info?.financeOrderStatus == 35 || info?.repaymentStatus == "ERROR" ? (
                <Button
                  type="primary"
                  onClick={() => {
                    setRepaymentModal(true)
                  }}
                >
                  确认还款
                </Button>
              ) : null}
            </Space>
          }
        >
          <Row>
            <Col span={8}>
              <div className="form_item">
                <span className="form_item_label">融资单号：</span>
                <div>{info?.id}</div>
              </div>
            </Col>
            <Col span={8}>
              <div className="form_item">
                <span className="form_item_label">融资产品：</span>
                <div>{info?.financeProductName}</div>
              </div>
            </Col>
            <Col span={8}>
              <div className="form_item">
                <span className="form_item_label">融资公司：</span>
                <div>{info?.financeCompany}</div>
              </div>
            </Col>
          </Row>{" "}
          <Row>
            <Col span={8}>
              <div className="form_item">
                <span className="form_item_label">关联订单：</span>
                <div>{info?.orderId}</div>
              </div>
            </Col>
            <Col span={8}>
              <div className="form_item">
                <span className="form_item_label">借款人：</span>
                <div>{info?.custName}</div>
              </div>
            </Col>
            <Col span={8}>
              <div className="form_item">
                <span className="form_item_label">核心企业：</span>
                <div>{info?.companyName}</div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col span={8}>
              <div className="form_item">
                <span className="form_item_label">借款金额：</span>
                <div>{info?.appAmt}</div>
              </div>
            </Col>
            <Col span={8}>
              <div className="form_item">
                <span className="form_item_label">借款周期：</span>
                <div>{info?.loanTerm}</div>
              </div>
            </Col>
            <Col span={8}>
              <div className="form_item">
                <span className="form_item_label">借款利率：</span>
                <div>
                  {info?.appRate &&
                    Number(new Decimal(info?.appRate).mul(new Decimal("100"))) + "%"}
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col span={8}>
              <div className="form_item">
                <span className="form_item_label">收款账号：</span>
                <div>{info?.loanBankCardInfo && info?.loanBankCardInfo?.acctNo}</div>
              </div>
            </Col>
            <Col span={8}>
              <div className="form_item">
                <span className="form_item_label">申请时间：</span>
                <div>{info?.createTime}</div>
              </div>
            </Col>
            <Col span={8}>
              <div className="form_item">
                <span className="form_item_label">状态：</span>
                {info?.status ? <Badge {...checkStatusEnumStatus[info?.financeStatus]} /> : null}
              </div>
            </Col>
          </Row>
        </Card>
        <Card title="放款信息" style={{ marginTop: 16 }}>
          <Row>
            <Col span={8}>
              <div className="form_item">
                <span className="form_item_label">放款金额：</span>
                <div>{info?.lmtAmt}</div>
              </div>
            </Col>
            <Col span={8}>
              <div className="form_item">
                <span className="form_item_label">放款时间：</span>
                <div>{info?.tranDate}</div>
              </div>
            </Col>
          </Row>
        </Card>
        {/* <Card
          className="financing-space"
          title={`还款信息`}
          extra={
            <Space>
              <Tag bordered={false} color="red" className="financing-space-tag">
                不支持提前还款
              </Tag>
            </Space>
          }
          style={{ marginTop: 16 }}
        >
          <Row>
            <Col span={8}>
              <div className="form_item">
                <span className="form_item_label">还款模式：</span>
                <div>{info?.name}</div>
              </div>
            </Col>
          </Row>
          <TableCom
            columns={accountColumns}
            dataSource={[{ sort: "1" }] || resultData}
            total={0}
            pagination={false}
            scroll={{
              x: true,
            }}
          ></TableCom>
        </Card> */}
        <Modal
          title="还款确认"
          open={repaymentModal}
          footer={null}
          onCancel={() => {
            setRepaymentModal(false)
            form.resetFields()
          }}
        >
          <Form form={form}>
            <Form.Item label="还款日期">
              <span>{today}</span>
            </Form.Item>
            <Form.Item label="还款类型">
              <span>{"结清贷款"}</span>
            </Form.Item>
            <Form.Item label="还款期数">
              <span>{"1期"}</span>
            </Form.Item>
            <Form.Item label="还款模式">
              <span>{"线下还款(客户还款)"}</span>
            </Form.Item>
            <div>
              <Form.Item
                label="还款金额"
                name="repayAmt"
                rules={[{ required: true, message: "请输入还款金额" }]}
              >
                <Input placeholder="请输入" style={{ width: 308 }}></Input>
              </Form.Item>
              <p style={{ paddingLeft: 120, color: "rgba(0, 0, 0, 0.45)" }}>
                点击后将提交资方查收还款信息，请再次确认
              </p>
            </div>
          </Form>
          <div style={{ width: "100%", borderTop: "1px solid #f0f0f0" }}>
            <div className="check-modal">
              <i></i>
              <div style={{ marginBottom: 10 }}>
                <Button
                  className="check-modal-button"
                  onClick={() => {
                    setRepaymentModal(false)
                    form.resetFields()
                  }}
                >
                  取消
                </Button>
                <Button type="primary" onClick={handPrimary}>
                  确认
                </Button>
              </div>
            </div>
          </div>
        </Modal>
      </DetailPageContent>
    </DetailPageContainer>
  )
}

export default FinancingOrderDetails
