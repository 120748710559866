/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react"
import { Button, Space } from "antd"
import { DetailPageContent } from "@src/components/DetailPageComponents/Content"
import { DetailPageHeader } from "@src/components/DetailPageComponents/Header"
import { DetailPageContainer } from "@src/components/DetailPageComponents/PageContainer"
import BreadCrumbBar from "../../../components/BreadCrumbBar"
import TableCom from "../../../components/TableCom"
import { dataSourceList } from "./constant"
import "./index.scss"
import { useNavigate } from "react-router-dom"
import { getOperAppsTions } from "../service"
import Access from "@src/components/Access"
import { useAccess } from "@src/contents/useAccess"
const PlatformSetupApply = () => {
  const navigate = useNavigate()
  const access = useAccess()
  const [loading, setLoading] = useState<boolean>(false)

  const [dataSource, setDataSource] = useState<any>(dataSourceList)
  const [paginationProps, setPaginationProps] = useState<{
    showQuickJumper: boolean
    pageSize: number
    current: number
    total: number
    // onChange: () => void
  }>({
    showQuickJumper: true,
    pageSize: 10,
    current: 1,
    total: 0,
    // onChange: () => { }
  })
  const getHandList = async (paramsPagination?: any) => {
    setLoading(true)
    let params = {
      size: paginationProps.pageSize,
      current: paginationProps.current,
      ...paramsPagination,
    }
    const result = await getOperAppsTions(params)
    if (result.code == 200) {
      setDataSource(result.data.records)
      setPaginationProps({
        ...paginationProps,
        current: Number(result?.data?.current),
        pageSize: Number(result?.data?.size),
        total: result?.data?.total,
      })

      setLoading(false)
    }
    console.log(result, "result")
  }
  const onChange = (params: any) => {
    setPaginationProps({
      ...paginationProps,
      ...params,
    })
    getHandList({ size: params.pageSize, current: params.current })
  }
  const applyList = [
    {
      title: "应用列表",
      dataIndex: "name",
    },
    {
      title: "应用ID",
      key: "since",
      dataIndex: "id",
      valueType: "date",
    },
    {
      title: "操作",
      render: (row, record) => (
        <Access accessible={access.op_platform_should_feature_detail}>
          <a onClick={() => navigate(`/platformSetup/apply/${record?.id}/${record?.name}`)}>详情</a>
        </Access>
      ),
    },
  ]
  useEffect(() => {
    getHandList()
  }, [])
  return (
    <DetailPageContainer>
      <DetailPageHeader
        title={
          <Space>
            <span>应用管理</span>
          </Space>
        }
        breadCrumb={[
          { label: "平台设置", path: "" },
          { label: "应用管理", path: "" },
        ]}
        rightContent={""}
      ></DetailPageHeader>
      <DetailPageContent>
        <div className="PlatformSetupApply_Content_user">
          <div className="PlatformSetupApply_Content_basie">
            <div className="PlatformSetupApply_Content_basic_div">应用列表/{dataSource.length}</div>
            <TableCom
              rowKey={(record: any) => record.id}
              columns={applyList}
              dataSource={dataSource}
              loading={loading}
              scroll={{
                x: true,
              }}
              style={{
                paddingTop: 16,
              }}
              pagination={{
                ...paginationProps,
                total: paginationProps.total,
              }}
              onChange={onChange}
            ></TableCom>
          </div>
        </div>
      </DetailPageContent>
    </DetailPageContainer>
  )
}
export default PlatformSetupApply
