import React, { useState, useEffect, useRef } from "react"
import { Button, message, Space, Badge } from "antd"
import { useNavigate } from "react-router-dom"
import AddModal from "./UserModal/AddModal"
import { DetailPageContent } from "@src/components/DetailPageComponents/Content"
import { DetailPageHeader } from "@src/components/DetailPageComponents/Header"
import { DetailPageContainer } from "@src/components/DetailPageComponents/PageContainer"
import "./index.scss"
import SKTable from "@src/components/SkTable"
import schema from "./schema"
import columnsP from "./columns"
import { getUserLists } from "../service"
import Access from "@src/components/Access"
import { COMPANY_INFO } from "@/contents/companyInfo"
import { useAccess } from "@src/contents/useAccess"
const User = () => {
  const tableRef = useRef<any>(null)
  const navigate = useNavigate()
  const [isAdd, setIsAdd] = useState<boolean>(false)
  const access = useAccess()
  const columns: any = [
    ...columnsP,
    {
      title: "操作",
      dataIndex: "operation",
      fixed: "right",
      render: (text: any, record: any) => {
        return (
          <div>
            <Access accessible={access.op_platform_feature_detail}>
              <Button type="link" onClick={() => navigate(`/platformSetup/user/${record?.id}`)}>
                详情
              </Button>
            </Access>
          </div>
        )
      },
    },
  ]
  const handleRequestData = async (params: any) => {
    const { status, keywords, current, pageSize, ...rest } = params
    const res: any = await getUserLists({
      keywords: keywords?.trim(),
      current,
      status,
      size: pageSize,
      ...rest,
    })
    return {
      data: res?.data.records,
      total: res?.data.total,
    }
  }
  const upDataTable = () => {
    tableRef.current.upData()
  }
  useEffect(() => {
    tableRef.current.setSearch("provider", {
      props: {
        options: COMPANY_INFO?.mark,
        changeOnSelect: true,
      },
    })
  }, [])
  return (
    <DetailPageContainer>
      <DetailPageHeader
        title={
          <Space>
            <span>用户管理</span>
          </Space>
        }
        breadCrumb={[
          { label: "平台设置", path: "" },
          { label: "用户管理", path: "" },
        ]}
        rightContent={""}
      ></DetailPageHeader>
      <DetailPageContent>
        <SKTable
          title={"用户管理"}
          ref={tableRef}
          toolbarRender={
            <>
              <Access accessible={access.op_platform_feature_add}>
                <Button
                  type="primary"
                  onClick={() => {
                    setIsAdd(true)
                  }}
                >
                  新增用户
                </Button>
              </Access>
            </>
          }
          pagination={{
            pageSize: 10,
            showQuickJumper: true,
            showTotal(total, range) {
              return `总共${total}条`
            },
          }}
          request={handleRequestData}
          pageChangeWithRequest={true}
          columns={columns}
          searchFlag={true}
          search={{
            mode: "simple",
            schema: schema,
          }}
        ></SKTable>
        <AddModal
          isAdd={isAdd}
          addModal={(value: boolean) => {
            setIsAdd(value)
          }}
          cb={upDataTable}
          options={COMPANY_INFO?.mark}
        />
      </DetailPageContent>
    </DetailPageContainer>
  )
}
export default User
