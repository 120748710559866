/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from "react"
import { Button, message, Space } from "antd"
import schema from "./schema"
import { useNavigate, Link } from "react-router-dom"
import columnsP from "./columns"
import SKTable from "@src/components/SkTable"
import { DetailPageContent } from "@src/components/DetailPageComponents/Content"
import { DetailPageHeader } from "@src/components/DetailPageComponents/Header"
import { DetailPageContainer } from "@src/components/DetailPageComponents/PageContainer"
import useTableTitle from "@src/hooks/useTableTitle"
import Access from "@src/components/Access"
import { useAccess } from "@src/contents/useAccess"
import { getRegionsList } from "../service"

const TradingScheme = () => {
  const tableRef = useRef<any>(null)
  const navigate = useNavigate()
  const access = useAccess()
  const { setTotal, tableTitle } = useTableTitle("交易方案")
  const [numList, setNumList] = useState<Number>(0)

  const handleRequestData = async (params: any) => {
    console.log(params, "params")
    const { current, pageSize, ...rest } = params
    const res: any = await getRegionsList({
      current,
      size: pageSize,
      ...rest,
    })
    setTotal(res?.total)
    setNumList(res.data.total)
    return {
      data: res?.data.records,
      total: res?.data.total,
    }
  }
  const columns: any = [
    ...columnsP,
    {
      title: "操作",
      dataIndex: "operation",
      width: 100,
      fixed: "right",
      render: (text: any, record: any) => {
        return (
          <div className="table-operate">
            {/* <Access accessible={access.op_platform_district_feature_detail}> */}
            <Link to={`/platformSetup/district/${record?.id}`}>详情</Link>
            {/* </Access> */}
          </div>
        )
      },
    },
  ]
  // useEffect(() => {}, [])
  return (
    <DetailPageContainer>
      <DetailPageHeader
        title={
          <Space>
            <span>税区管理</span>
          </Space>
        }
        breadCrumb={[
          { label: "平台设置", path: "" },
          { label: "税区管理", path: "" },
        ]}
        rightContent={""}
      ></DetailPageHeader>
      <DetailPageContent>
        <SKTable
          title={`税区管理列表/${numList}`}
          ref={tableRef}
          toolbarRender={
            <>
              {/* <Access accessible={access.op_platform_district_feature_add}> */}
              <Button
                type="primary"
                className="btn-add"
                onClick={() => navigate(`/platformSetup/district/create/${null}`)}
              >
                新增税区
              </Button>
              {/* </Access> */}
            </>
          }
          pagination={{
            pageSize: 20,
            showQuickJumper: true,
            showTotal(total, range) {
              return `总共${total}条`
            },
          }}
          request={handleRequestData}
          pageChangeWithRequest={true}
          columns={columns}
          searchFlag={false}
          // search={{
          //   mode: "simple",
          // }}
        ></SKTable>
      </DetailPageContent>
    </DetailPageContainer>
  )
}

export default TradingScheme
