import React, { useState, useEffect } from "react"
export function useAccess() {
  const [access, setAccess] = useState({})
  useEffect(() => {
    let obj = {}
    JSON.parse(sessionStorage.getItem("ACCESS_KEYS") || "[]").forEach((key) => {
      obj[key] = key
    })
    setAccess(obj)
  }, [])
  return access
}
