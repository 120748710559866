/* eslint-disable react-hooks/exhaustive-deps */
import { PaperClipOutlined, RollbackOutlined } from "@ant-design/icons"
import { DetailPageContent } from "@src/components/DetailPageComponents/Content"
import { DetailPageHeader } from "@src/components/DetailPageComponents/Header"
import { DetailPageContainer } from "@src/components/DetailPageComponents/PageContainer"
import TableCom from "@src/components/TableCom"
import { Button, Card, Col, Input, Row, Space, Switch, Tooltip } from "antd"
import React, { useEffect, useMemo, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import {
  getContractList,
  getOperRegionsDetails,
  getOrderUploadByPath,
  getPutRegionsList,
} from "../../service"
import { dualPrefixEnumDetails, prodListEnum } from "../constant"
import styles from "./index.module.scss"
const TaxDistrictDetail = () => {
  const navigate = useNavigate()
  const searchParams = useParams()
  const [info, setInfo] = useState<any>({})
  const [agreementTemplateId, setAgreementTemplateId] = useState<any>([])
  const [applyLogoutTemplateId, setAGapplyLogoutTemplateId] = useState<any>([])
  const [switchStats, setSwitchStats] = useState<boolean>()

  const getInfo = async (id: any) => {
    if (!id) return
    const res = await getOperRegionsDetails(id)
    if (res?.code === 200) {
      setInfo(res?.data)
      setSwitchStats(res?.data.status)
      handGetContractList(res.data)
    }
  }
  const handGetContractList = async (info) => {
    const result = await getContractList({
      size: -1,
      searchCount: true,
      current: 1,
    })
    if (result.code == 200) {
      setAgreementTemplateId(
        result.data.records.filter((ie) => ie.id == info?.flowConfig?.agreementTemplateId)
      )
      setAGapplyLogoutTemplateId(
        result.data.records.filter((ie) => ie.id == info?.flowConfig?.applyLogoutTemplateId)
      )
    }
  }
  useEffect(() => {
    getInfo(searchParams?.id)
  }, [searchParams])

  const invoiceColumns = [
    {
      title: "文件类型",
      dataIndex: "fileType",
      render: (text, record) => {
        return text == "FILE" ? "文档" : "图片"
      },
    },
    {
      title: "是否必填",
      dataIndex: "allowEmpty",
      render: (text, record) => {
        return text ? "是" : "否"
      },
    },
    {
      title: "上传数量",
      dataIndex: "fileNumbers",
    },
    {
      title: "要求说明",
      dataIndex: "fileRequire",
    },
    {
      title: "文件名称",
      dataIndex: "fileName",
    },
  ]
  /**
   * 获取文件信息，下载文件
   * @param fileKey
   */
  const getFileUrl = async (fileKey: string) => {
    console.log(fileKey, "fileKey")
    let params = {
      filePaths: [info?.flowConfig?.agreementExampleFileId],
    }
    const result: any = await getOrderUploadByPath(params)
    if (result.code == 200) {
      let download_a = document.createElement("a")
      download_a.setAttribute("href", result?.data?.files[0]?.downloadUrl)
      download_a.setAttribute("target", "_blank")
      download_a.setAttribute("id", "camnpr")
      document.body.appendChild(download_a)
      download_a.click()
      document.body.removeChild(download_a)
    }
  }
  const { displayOperations, fullOperations } = useMemo(() => {
    const fullOps = info?.operationAuthList?.map((item) => item.operationName).join("、")
    const displayOps =
      info?.operationAuthList?.length > 6
        ? info?.operationAuthList
            ?.slice(0, 6)
            ?.map((item) => item.operationName)
            .join("、") + "..."
        : fullOps

    return { displayOperations: displayOps, fullOperations: fullOps }
  }, [info?.operationAuthList])
  return (
    <DetailPageContainer>
      <DetailPageHeader
        title={
          <Space>
            <Button
              onClick={() => navigate("/platformSetup/district")}
              icon={<RollbackOutlined />}
              style={{ color: "#1890FF" }}
            >
              返回
            </Button>
            <span style={{ paddingLeft: 4 }}>税区详情</span>
          </Space>
        }
        breadCrumb={[
          { label: "平台设置", path: "" },
          { label: "税区管理", path: "" },
          { label: "详情", path: "" },
        ]}
        rightContent={
          <>
            {/* <Access accessible={access.op_platform_district_feature_edit}> */}
            <div style={{ display: "flex", alignItems: "center", marginRight: 25 }}>
              <p style={{ width: 95, marginTop: 14 }}>税区状态：</p>
              <Switch
                checked={switchStats}
                checkedChildren="有效"
                unCheckedChildren="失效"
                onChange={async (value) => {
                  const result: any = await getPutRegionsList({
                    id: info.id,
                    status: value ? 1 : 0,
                  })
                  if (result.code == 200) {
                    getInfo(searchParams?.id)
                    setSwitchStats(value)
                  }
                }}
              />
              <Button
                style={{ marginLeft: 16 }}
                type="primary"
                onClick={() => navigate(`/platformSetup/district/create/${info?.id}`)}
              >
                编辑
              </Button>
            </div>
            {/* </Access> */}
          </>
        }
      ></DetailPageHeader>
      <DetailPageContent>
        <div className={styles.supply_order_container}>
          <Card title="基础信息">
            <Row>
              <Col span={8}>
                <div className={styles.info_items}>
                  <span className={styles.label}>税区名称：</span>
                  <span className={styles.value}>{info?.name}</span>
                </div>
              </Col>
              <Col span={8}>
                <div className={styles.info_items}>
                  <span className={styles.label}>运营方：</span>
                  <Tooltip title={fullOperations}>
                    <span className={styles.value}>{displayOperations}</span>
                  </Tooltip>
                </div>
              </Col>
            </Row>
          </Card>
          <Card title="流程配置" style={{ marginTop: 16 }}>
            <Row gutter={12} className={styles.row_container}>
              <Col span={3} className={styles.only_read}>
                是否需要双录视频：
              </Col>
              <Col span={5}>{info?.flowConfig?.isNeedDual ? "是" : "否"}</Col>
              <Col span={3} className={styles.only_read}>
                是否需要委托协议：
              </Col>
              <Col span={5}>{info?.flowConfig?.isNeedAgreement ? "是" : "否"}</Col>
              <Col span={3} className={styles.only_read}>
                委托办理方：
              </Col>
              <Col span={5}>{info?.organizationName}</Col>
            </Row>

            <Row gutter={12} className={styles.row_container}>
              <Col span={3} className={styles.only_read}>
                选择委托协议：
              </Col>
              <Col span={5}>{agreementTemplateId[0]?.name}</Col>
              <Col span={3} className={styles.only_read}>
                上传委托协议示例：
              </Col>
              <Col
                style={{ color: "#1890ff", cursor: "pointer" }}
                span={5}
                onClick={() => {
                  getFileUrl(info?.flowConfig?.agreementExampleFileId)
                }}
              >
                <span style={{ color: "rgba(0, 0, 0, 0.45)" }}>
                  <PaperClipOutlined />
                </span>
                {info?.flowConfig?.agreementExampleName}
              </Col>
              <Col span={3} className={styles.only_read}>
                选择注销申请模版：
              </Col>
              <Col span={5}>{applyLogoutTemplateId[0]?.name}</Col>
            </Row>
            <Row gutter={12} className={styles.row_container}>
              <Col span={3} className={styles.only_read}>
                店铺地址生成规则：
              </Col>
              <Col span={5}>
                {
                  prodListEnum.filter((item) => item.value == info?.flowConfig?.siteShowType)?.[0]
                    ?.label
                }
              </Col>
            </Row>
          </Card>
          <Card title="个体户注册信息配置" style={{ marginTop: 16 }}>
            <Row gutter={12} className={styles.row_container} style={{ marginTop: -16 }}>
              <Col span={5} className={styles.only_read}>
                邮编：
              </Col>
              <Col span={8}>{info?.postcode}</Col>
            </Row>
            <Row gutter={12} className={styles.row_container}>
              <Col span={5} className={styles.only_read}>
                个体户办理是否否采用不核名：
              </Col>
              <Col span={8}>{info?.supportAnonymous ? "允许不核名" : "不允许不核名"}</Col>
            </Row>
            <Row gutter={12} className={styles.row_container}>
              <Col span={5} className={styles.only_read}>
                经营范围配置：
              </Col>
              <Col span={8}>{info?.businessScope}</Col>
            </Row>
            <Row gutter={12} className={styles.row_container}>
              <Col span={5} className={styles.only_read}>
                个体户名称前缀：
              </Col>
              <Col span={8}>{info?.individualBusinessPrefix}</Col>
            </Row>
            <Row gutter={12} className={styles.row_container}>
              <Col span={5} className={styles.only_read}>
                个体户名称后缀：
              </Col>
              <Col span={8}>{info?.individualBusinessSuffix}</Col>
            </Row>
            <Row gutter={12} className={styles.row_container}>
              <Col span={5} className={styles.only_read}>
                预览 企业/个体户名称：
              </Col>
              <Col>
                <Input
                  addonBefore={info.individualBusinessPrefix}
                  addonAfter={info.individualBusinessSuffix}
                  disabled={true}
                  defaultValue={"企业名称"}
                />
              </Col>
            </Row>
            <div style={{ paddingTop: 10 }}>
              <span className={styles.text}>额外上传文件配置</span>
              <TableCom
                style={{ paddingTop: 10 }}
                columns={invoiceColumns}
                dataSource={info?.extraFileConfigs}
                total={0}
                pagination={false}
                scroll={{
                  x: true,
                }}
              ></TableCom>
            </div>
          </Card>
          <Card title="移动端文案提示" style={{ marginTop: 16 }}>
            <Row gutter={12} className={styles.row_container}>
              <Col span={5} className={styles.only_read}>
                移动端提交办理申请后提示：
              </Col>
              <Col span={8}>{info?.handlingPrompt}</Col>
            </Row>
            <Row gutter={12} className={styles.row_container}>
              <Col span={5} className={styles.only_read}>
                移动端营业执照办理成功后提示：
              </Col>
              <Col span={8}>{info?.completedPrompt}</Col>
            </Row>
            <Row gutter={12} className={styles.row_container}>
              <Col span={5} className={styles.only_read}>
                移动端税务登记成功后提示：
              </Col>
              <Col span={8}>{info?.registeredPrompt}</Col>
            </Row>
            <Row gutter={12} className={styles.row_container}>
              <Col span={5} className={styles.only_read}>
                移动端审核成功后提示：
              </Col>
              <Col span={8}>{info?.checkCompletePrompt}</Col>
            </Row>
          </Card>
          <Card title="个体户代办企业微信通知配置" style={{ marginTop: 16 }}>
            <Col span={5}>提交办理申请后：</Col>
            <Row gutter={12} className={styles.row_container}>
              <Col span={5} className={styles.only_read}>
                机器人webhookurl：
              </Col>
              <Col span={8}>{info?.notifyConfig?.SUBMIT_APPLY?.webhookUrl}</Col>
            </Row>
            <Row gutter={12} className={styles.row_container}>
              <Col span={5} className={styles.only_read}>
                文案内容：
              </Col>
              <Col span={8}>{info?.notifyConfig?.SUBMIT_APPLY?.context}</Col>
            </Row>
            <Col span={5}>审核成功后提示：</Col>
            <Row gutter={12} className={styles.row_container}>
              <Col span={5} className={styles.only_read}>
                机器人webhookurl：
              </Col>
              <Col span={8}>{info?.notifyConfig?.CHECK_COMPLETE?.webhookUrl}</Col>
            </Row>
            <Row gutter={12} className={styles.row_container}>
              <Col span={5} className={styles.only_read}>
                文案内容：
              </Col>
              <Col span={8}>{info?.notifyConfig?.CHECK_COMPLETE?.context}</Col>
            </Row>
            <Col span={5}>营业执照办理成功后提示：</Col>
            <Row gutter={12} className={styles.row_container}>
              <Col span={5} className={styles.only_read}>
                机器人webhookurl：
              </Col>
              <Col span={8}>{info?.notifyConfig?.LICENSE_COMPLETE?.webhookUrl}</Col>
            </Row>
            <Row gutter={12} className={styles.row_container}>
              <Col span={5} className={styles.only_read}>
                文案内容：
              </Col>
              <Col span={8}>{info?.notifyConfig?.LICENSE_COMPLETE?.context}</Col>
            </Row>
            <Col span={5}>通知办税人清税：</Col>
            <Row gutter={12} className={styles.row_container}>
              <Col span={5} className={styles.only_read}>
                机器人webhookurl：
              </Col>
              <Col span={8}>{info?.notifyConfig?.CLEARANCE_TAX?.webhookUrl}</Col>
            </Row>
            <Row gutter={12} className={styles.row_container}>
              <Col span={5} className={styles.only_read}>
                文案内容：
              </Col>
              <Col span={8}>{info?.notifyConfig?.CLEARANCE_TAX?.context}</Col>
            </Row>
            <Col span={5}>通知营业执照办理人注销：</Col>
            <Row gutter={12} className={styles.row_container}>
              <Col span={5} className={styles.only_read}>
                机器人webhookurl：
              </Col>
              <Col span={8}>{info?.notifyConfig?.LOGOUT?.webhookUrl}</Col>
            </Row>
            <Row gutter={12} className={styles.row_container}>
              <Col span={5} className={styles.only_read}>
                文案内容：
              </Col>
              <Col span={8}>{info?.notifyConfig?.LOGOUT?.context}</Col>
            </Row>
          </Card>
          <Card title="个体户代办运营放标识" style={{ marginTop: 16 }}>
            <Row gutter={12} className={styles.row_container}>
              <Col span={5} className={styles.only_read}>
                双录模版标识：
              </Col>
              <Col span={8}>
                {dualPrefixEnumDetails[info?.operatorIdentificationConfig?.dualPrefix]}
              </Col>
            </Row>
          </Card>
        </div>
      </DetailPageContent>
    </DetailPageContainer>
  )
}

export default TaxDistrictDetail
