/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useEffect, useRef } from "react"
import { Button, message, Space, Badge, Modal, Form, Select, Input } from "antd"
import { useNavigate } from "react-router-dom"
import { DetailPageContent } from "@src/components/DetailPageComponents/Content"
import { DetailPageHeader } from "@src/components/DetailPageComponents/Header"
import { DetailPageContainer } from "@src/components/DetailPageComponents/PageContainer"
import "./index.scss"
import SKTable from "@src/components/SkTable"
import schema from "./schema"
import columnsP from "./columns"
import { getTissueList, getLoanCompany, getLoanCompanyAdd } from "../service"
import Access from "@src/components/Access"
import { useAccess } from "@src/contents/useAccess"

const CoreEnterprise = () => {
  const tableRef = useRef<any>(null)
  const navigate = useNavigate()
  const access = useAccess()
  const [form] = Form.useForm()
  const [tissueList, setTissueList] = useState<any>([]) //组织列表
  const [repaymentModal, setRepaymentModal] = useState<any>(false)
  const [tissueFlag, setTissueFlag] = useState<any>(false)

  const columns: any = [
    ...columnsP,
    {
      title: "操作",
      dataIndex: "operation",
      fixed: "right",
      render: (text: any, record: any) => {
        return (
          <div>
            <Button type="link" onClick={() => navigate(`/finance/enterprise/${record?.id}`)}>
              详情
            </Button>
          </div>
        )
      },
    },
  ]
  //组织列表
  const tissueInit = async () => {
    const response = await getTissueList({
      size: -1,
      searchCount: true,
      types: "ENTERPRISE",
      status: true,
    })
    if (response?.code == 200) {
      const result = response?.data.records.map((ie) => {
        return {
          value: ie.id,
          label: ie.name,
          taxNo: ie.idNo,
        }
      })
      setTissueList(result)
    }
  }
  const onHandFinish = async () => {
    await form.validateFields()
    const values = form.getFieldsValue()
    console.log(values, "values")
    let params = {
      ...values,
    }
    const resultData: any = await getLoanCompanyAdd(params)
    if (resultData.code == 200) {
      message.success("添加成功")
      form.resetFields()
      setRepaymentModal(false)
      upDataTable()
    }
  }

  const handleRequestData = async (params: any) => {
    const { name, current, pageSize, ...rest } = params
    const res: any = await getLoanCompany({
      name: name?.trim(),
      current,
      size: pageSize,
      ...rest,
    })
    return {
      data: res?.data?.records,
      total: res?.data?.total,
    }
  }
  const upDataTable = () => {
    tableRef.current.upData()
  }
  useEffect(() => {
    tissueInit()
  }, [])
  return (
    <DetailPageContainer>
      <DetailPageHeader
        title={
          <Space>
            <span>核心企业准入</span>
          </Space>
        }
        breadCrumb={[
          { label: "供应链金融", path: "" },
          { label: "核心企业准入", path: "" },
        ]}
        rightContent={""}
      ></DetailPageHeader>
      <DetailPageContent>
        <SKTable
          title={"核心企业准入列表"}
          ref={tableRef}
          toolbarRender={
            <>
              <Button type="primary" onClick={() => setRepaymentModal(true)}>
                添加核心企业
              </Button>
            </>
          }
          pagination={{
            pageSize: 10,
            showQuickJumper: true,
            showTotal(total, range) {
              return `总共${total}条`
            },
          }}
          request={handleRequestData}
          pageChangeWithRequest={true}
          columns={columns}
          searchFlag={true}
          search={{
            mode: "simple",
            schema: schema,
          }}
        ></SKTable>
        <Modal
          title="添加核心企业"
          open={repaymentModal}
          footer={null}
          onCancel={() => {
            form.resetFields()
            setRepaymentModal(false)
          }}
        >
          <Form form={form} autoComplete="off" className="newLogout-from">
            <Form.Item
              name="organizationId"
              label={"企业名称"}
              rules={[{ required: true, message: "请选择" }]}
            >
              <Select
                style={{ width: 336 }}
                placeholder="请选择"
                onChange={async (e) => {}}
                filterOption={(input: any, option: any) =>
                  (option?.label ?? "").includes(input) || (option?.contact ?? "").includes(input)
                }
                onSelect={(_, option: any) => {
                  form.setFieldValue("taxNo", option.taxNo)
                  setTissueFlag(true)
                }}
                onClear={() => {
                  form.setFieldValue("taxNo", null)
                  setTissueFlag(false)
                }}
                options={tissueList}
                allowClear
                showSearch
              ></Select>
            </Form.Item>
            <Form.Item
              name="taxNo"
              label={"核心企业证件号"}
              rules={[{ required: true, message: "请输入" }]}
            >
              <Input placeholder="请输入" disabled={tissueFlag} style={{ width: 336 }}></Input>
            </Form.Item>
            <Form.Item
              name="legalIdNo"
              label={"法人身份证号"}
              rules={[
                { required: true, message: "请输入" },
                {
                  pattern:
                    /^[1-9]\d{5}(19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/,
                  message: "请输入正确的身份证号",
                },
              ]}
            >
              <Input placeholder="请输入" style={{ width: 336 }}></Input>
            </Form.Item>
          </Form>
          <div style={{ width: "100%", borderTop: "1px solid #f0f0f0" }}>
            <div className="check-modal">
              <i></i>
              <div style={{ marginBottom: 10 }}>
                <Button
                  className="check-modal-button"
                  onClick={() => {
                    form.resetFields()
                    setRepaymentModal(false)
                  }}
                >
                  取消
                </Button>
                <Button type="primary" onClick={onHandFinish}>
                  确认
                </Button>
              </div>
            </div>
          </div>
        </Modal>
      </DetailPageContent>
    </DetailPageContainer>
  )
}
export default CoreEnterprise
