/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from "react"
import { Button, message, Space } from "antd"
import schema from "./schema"
import { useNavigate, Link } from "react-router-dom"
import columnsP from "./columns"
import SKTable from "@src/components/SkTable"
import { DetailPageContent } from "@src/components/DetailPageComponents/Content"
import { DetailPageHeader } from "@src/components/DetailPageComponents/Header"
import { DetailPageContainer } from "@src/components/DetailPageComponents/PageContainer"
import useTableTitle from "@src/hooks/useTableTitle"

import { getTradingScheme, getTaxs, closePructor } from "../promises"
import { customerStatusList } from "./constant"
import Access from "@src/components/Access"
import { useAccess } from "@src/contents/useAccess"
import { getRangeDateOnUnitOfTime } from "../../../utils/date/utils"
const TradingScheme = () => {
  const access = useAccess()
  const tableRef = useRef<any>(null)
  const navigate = useNavigate()
  const { setTotal, tableTitle } = useTableTitle("交易方案")
  const [numList, setNumList] = useState<Number>(0)

  const handleRequestData = async (params: any) => {
    console.log(params, "params")
    const { name, status, startTime, endTime, current, pageSize, ...rest } = params
    const res: any = await getTradingScheme({
      name: name?.trim(),
      current,
      status,
      ...getRangeDateOnUnitOfTime({
        startTime: startTime,
        endTime: endTime,
      }),
      size: pageSize,
      ...rest,
    })
    setTotal(res?.total)
    setNumList(res.data.total)
    return {
      data: res?.data.records,
      total: res?.data.total,
    }
  }
  const columns: any = [
    ...columnsP,
    {
      title: "操作",
      dataIndex: "operation",
      fixed: "right",
      render: (text: any, record: any) => {
        return (
          <div className="table-operate">
            <Access accessible={access.op_entity_tradingScheme_feture_detail}>
              <Link to={`/entity/transaction/tradingScheme/${record?.id}`}>详情</Link>
            </Access>
          </div>
        )
      },
    },
  ]
  // useEffect(() => {}, [])
  return (
    <DetailPageContainer>
      <DetailPageHeader
        title={
          <Space>
            <span>交易方案</span>
          </Space>
        }
        breadCrumb={[
          { label: "交易中心", path: "" },
          { label: "交易方案", path: "" },
        ]}
        rightContent={""}
      ></DetailPageHeader>
      <DetailPageContent>
        <SKTable
          title={`交易方案列表/${numList}`}
          ref={tableRef}
          toolbarRender={
            <>
              <Access accessible={access.op_entity_tradingScheme_feture_add}>
                <Button
                  type="primary"
                  className="btn-add"
                  onClick={() => navigate(`/entity/transaction/TradingSchemeDetail/${null}`)}
                >
                  新建交易方案
                </Button>
              </Access>
            </>
          }
          pagination={{
            pageSize: 10,
            showQuickJumper: true,
            showTotal(total, range) {
              return `总共${total}条`
            },
          }}
          request={handleRequestData}
          pageChangeWithRequest={true}
          columns={columns}
          searchFlag={true}
          search={{
            mode: "simple",
            schema: schema,
          }}
        ></SKTable>
      </DetailPageContent>
    </DetailPageContainer>
  )
}

export default TradingScheme
